import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/marketanaly.css";
import { FaBtc } from "react-icons/fa";
import { BsArrowDown } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";
import TradingViewWidget, { Themes, BarStyles } from "react-tradingview-widget";

const Marketanalytical = () => {
  const currencychart = useRef();
  const exchangerate = useRef();

  const navigate = useNavigate();

  const Change = () => {
    navigate("/pipcalc");
  };
  const [currency, setCurrency] = useState();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "in",
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: true,
      showFloatingTooltip: false,
      width: "100%",
      height: "660",
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(106, 109, 120, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Forex",
          symbols: [
            {
              s: "FX:EURUSD",
              d: "EUR/USD",
            },
            {
              s: "FX:GBPUSD",
              d: "GBP/USD",
            },
            {
              s: "FX:USDJPY",
              d: "USD/JPY",
            },
            {
              s: "FX:USDCHF",
              d: "USD/CHF",
            },
            {
              s: "FX:AUDUSD",
              d: "AUD/USD",
            },
            {
              s: "FX:USDCAD",
              d: "USD/CAD",
            },
          ],
          originalTitle: "Forex",
        },
      ],
    });

    currencychart.current.appendChild(script);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";

    script.async = false;

    script.innerHTML = JSON.stringify({
      width: "100%",
      height: 400,
      currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD"],
      isTransparent: false,
      colorTheme: "light",
      locale: "in",
    });
    exchangerate.current.appendChild(script);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <div className="marketanalytical">
        <section className="marketbanner">
          <div className="marketanalybb">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 ">
                  <h1 className=" mb-2">Market Analysis</h1>
                  <p className="mb-3">
                    Take advantage of an opportunity with high-impact economic
                    event commentary, videos, and more from our  market
                    analysts
                  </p>
                </div>

                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/marketanaly/marketanalybb.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="market">
          <div className="marketanaly pb-5 mb-5 mt-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-2">
                  <div className="markettab pb-5 mb-5 mt-4">
                    <div className="nav-tabs-menu">
                      <ul
                        className="nav nav-tabs d-flex"
                        id="ProductTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="Currecny-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Currecny"
                            type="button"
                            role="tab"
                            aria-controls="Currecny"
                            aria-selected="true"
                          >
                            Currencies
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div className="tab-content" id="ProductTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="Currecny"
                        role="tabpanel"
                        aria-labelledby="Currecny-tab"
                      >
                        <div className="curre">
                          <div ref={currencychart}></div>

                          <div className="marketbtn  d-block flex-column align-items-center">
                            <Link
                              to="https://crm.milliva.com/client/register/6169896c79fd7"
                              className="optrade btn mb-3"
                            >
                              Open trading account
                            </Link>
                            <Link to="/mt5" className="downtrade btn">
                              Download trading platform
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Crypto"
                        role="tabpanel"
                        aria-labelledby="Crypto-tab"
                      >
                        <div className="curre">
                          <img
                            src={require("../../assets/images/marketanaly/marketchart.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">EURUSD</a>
                                  </td>
                                  <td className="val1">
                                    {" "}
                                    <a href="#">1.06</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">0.58</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -0.25%{" "}
                                      <i className="fas fa-angle-down  mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">GBPUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.2062</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.3967</a>
                                  </td>
                                  <td className="val4">
                                    <a href="">
                                      -0.23%{" "}
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDJPY</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">130.15</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.36</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.2%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCHF</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">0.9237</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.365</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.9%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCAD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.3560</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.51</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.8%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">AUDUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.298</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.0013</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.8%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="marketbtn  d-block flex-column align-items-center">
                            <a className="optrade btn mb-3" href="#">
                              Open trading account
                            </a>
                            <a className="downtrade btn" href="#">
                              Download trading platform
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Shares"
                        role="tabpanel"
                        aria-labelledby="Shares-tab"
                      >
                        <div className="curre">
                          <img
                            src={require("../../assets/images/marketanaly/marketchart.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">EURUSD</a>
                                  </td>
                                  <td className="val1">
                                    {" "}
                                    <a href="#">1.066</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">0.222</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -0.15%{" "}
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">GBPUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.2072</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.967</a>
                                  </td>
                                  <td className="val4">
                                    <a href="">
                                      -0.52%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDJPY</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.995</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.36</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.2%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCHF</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">0.9537</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.465</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.9%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCAD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.7896</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.011</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.5%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">AUDUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.898</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.0013</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.3%
                                      <i className="fa fa-angle-down  mx-2"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="marketbtn  d-block flex-column align-items-center">
                            <a className="optrade btn mb-3" href="#">
                              Open trading account
                            </a>
                            <a className="downtrade btn" href="#">
                              Download trading platform
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8">
                  <div className="forexanalytical mt-3">
                    <div className="nav-tabs-menu">
                      <ul
                        className="nav nav-tabs d-flex"
                        id="ProductTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="Forexanaly-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Forexanaly"
                            type="button"
                            role="tab"
                            aria-controls="Forexanaly"
                            aria-selected="true"
                          >
                            Fresh analysis articles
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="Topanaly-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Topanaly"
                            type="button"
                            role="tab"
                            aria-controls="Topanaly"
                            aria-selected="false"
                          >
                            Top analysis articles
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="ProductTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="Forexanaly"
                        role="tabpanel"
                        aria-labelledby="Forexanaly-tab"
                      >
                        <div className="forexanal">
                          <div className="row">
                            <div className="col-md-12 col-lg-6">
                              <a href="#">
                                <div className="card-flyer mt-4">
                                  <div className="text-box">
                                    <div className="image-box">
                                      <img
                                        src={require("../../assets/images/marketanaly/marketanalyimg.jpg")}
                                        alt="logo"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="forexanalybox">
                                      <h5>
                                        EUR/USD opened surprisingly calm this
                                        year. In previous years, it traded
                                        within a narrow range during Christmas
                                        and the first working days of the New
                                        Year, but now it was in a wide range,
                                        consolidating under the highs.{" "}
                                      </h5>
                                      <p className="mb-3">
                                        EUR/USD: price stays within the December
                                        range{" "}
                                      </p>
                                      <div className="forexusertime d-flex justify-content-between">
                                        <div className="forexananame">
                                          <h6>Mihail Makarov</h6>
                                        </div>
                                        <div className="timesec">
                                          <h6>30 minutes ago</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-md-12 col-lg-6">
                              <div className="forexanal2 mt-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="article1">
                                      <a href="#" className="card">
                                        <h6>
                                          Technical Analysis of Daily Price
                                          Movement of GBP/USD Main Currency
                                          Pair, Tuesday Januari 03 2023
                                        </h6>
                                        <p>Arief Makmur</p>
                                        <p>
                                          <small>4 hour 52 minutes ago</small>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div className=" col-lg-6">
                                    <div className="article1">
                                      <a href="#" className="card">
                                        <h6>
                                          Technical Analysis of Daily Price
                                          Movement of Gold Commodity Asset,
                                          Tuesday January 03 2023{" "}
                                        </h6>
                                        <p>Arief Makmur</p>
                                        <p>
                                          <small>4 hour 52 minutes ago </small>{" "}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="forexanal2 mt-4">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="article1">
                                      <a href="#" className="card">
                                        <h6>
                                          Outlook and trading signals for
                                          EUR/USD on January 3. COT report.
                                          Analysis of market situation. The euro
                                          continues to celebrate the New Year.
                                        </h6>
                                        <p>Arief Makmur</p>
                                        <p>
                                          <small>4 hour 52 minutes ago</small>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div className=" col-lg-6">
                                    <div className="article1">
                                      <a href="#" className="card">
                                        <h6>
                                          Elliott wave analysis of USD/JPY for
                                          January 3, 2023{" "}
                                        </h6>
                                        <p>Arief Makmur</p>
                                        <p>
                                          <small>4 hour 52 minutes ago </small>{" "}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bloscontents mt-4">
                          <div className="row">
                            <div className="col-md-12 col-lg-4">
                              <a href="#"></a>
                              <div className="card-flyer mt-4">
                                <a href="#"></a>
                                <div className="text-box">
                                  <a href="#">
                                    <div className="image-box">
                                      <img
                                        src={require("../../assets/images/marketanaly/marketanalyimg.jpg")}
                                        alt="logo"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </a>
                                  <div className="blogbox">
                                    <a href="#"></a>
                                    <a href="#" className="btn forex mb-2">
                                      Fundamental analysis
                                    </a>
                                    <h6 className="mb-2">
                                      Lower oil prices for 2023
                                    </h6>
                                    <p>
                                      2022 was a year of increased volatility
                                      and market division for oil to those who
                                      buy from Russia and those who refuse it.
                                      Brent ended it at +4%.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <a href="#"></a>
                              <div className="card-flyer mt-4">
                                <a href="#"></a>
                                <div className="text-box">
                                  <a href="#">
                                    <div className="image-box">
                                      <img
                                        src={require("../../assets/images/marketanaly/marketanalyimg.jpg")}
                                        alt="logo"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </a>
                                  <div className="blogbox">
                                    <a href="#"></a>
                                    <a href="#" className="btn forex mb-2">
                                      Fundamental analysis
                                    </a>
                                    <h6 className="mb-2">
                                      Lower oil prices for 2023
                                    </h6>
                                    <p>
                                      2022 was a year of increased volatility
                                      and market division for oil to those who
                                      buy from Russia and those who refuse it.
                                      Brent ended it at +4%.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                              <a href="#"></a>
                              <div className="card-flyer mt-4">
                                <a href="#"></a>
                                <div className="text-box">
                                  <a href="#">
                                    <div className="image-box">
                                      <img
                                        src={require("../../assets/images/marketanaly/analychart5.jpg")}
                                        alt="logo"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </a>
                                  <div className="blogbox">
                                    <a href="#"></a>
                                    <a href="#" className="btn forex mb-2">
                                      Fundamental analysis
                                    </a>
                                    <h6 className="mb-2">
                                      Upcoming economic data will set the tone
                                      of markets
                                    </h6>
                                    <p>
                                      Ahead are important events and economic
                                      statistics, which should determine the
                                      dynamics of markets in the first days of
                                      the new year. The first thing to look..
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="blog2">
                            <div className="row">
                              <div className="col-md-12 col-lg-4">
                                <a href="#"></a>
                                <div className="card-flyer mt-4">
                                  <a href="#"></a>
                                  <div className="text-box">
                                    <a href="#">
                                      <div className="image-box">
                                        <img
                                          src={require("../../assets/images/marketanaly/analychart4.jpg")}
                                          alt="logo"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </a>
                                    <div className="blogbox">
                                      <a href="#"></a>
                                      <a href="#" className="btn forex mb-2">
                                        Fundamental analysis
                                      </a>
                                      <h6 className="mb-2">
                                        Lower oil prices for 2023
                                      </h6>
                                      <p>
                                        2022 was a year of increased volatility
                                        and market division for oil to those who
                                        buy from Russia and those who refuse it.
                                        Brent ended it at +4%.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-4">
                                <a href="#"></a>
                                <div className="card-flyer mt-4">
                                  <a href="#"></a>
                                  <div className="text-box">
                                    <a href="#">
                                      <div className="image-box">
                                        <img
                                          src={require("../../assets/images/marketanaly/analychart2.jpg")}
                                          alt="logo"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </a>
                                    <div className="blogbox">
                                      <a href="#"></a>
                                      <a href="#" className="btn forex mb-2">
                                        Fundamental analysis
                                      </a>
                                      <h6 className="mb-2">
                                        Lower oil prices for 2023
                                      </h6>
                                      <p>
                                        2022 was a year of increased volatility
                                        and market division for oil to those who
                                        buy from Russia and those who refuse it.
                                        Brent ended it at +4%.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-4">
                                <a href="#"></a>
                                <div className="card-flyer mt-4">
                                  <a href="#"></a>
                                  <div className="text-box">
                                    <a href="#">
                                      <div className="image-box">
                                        <img
                                          src={require("../../assets/images/marketanaly/analychart3.jpg")}
                                          alt="logo"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </a>
                                    <div className="blogbox">
                                      <a href="#"></a>
                                      <a href="#" className="btn forex mb-2">
                                        Fundamental analysis
                                      </a>
                                      <h6 className="mb-2">
                                        Upcoming economic data will set the tone
                                        of markets
                                      </h6>
                                      <p>
                                        Ahead are important events and economic
                                        statistics, which should determine the
                                        dynamics of markets in the first days of
                                        the new year. The first thing to look..
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Topanaly"
                        role="tabpanel"
                        aria-labelledby="Topanaly-tab"
                      >
                        <div className="forextab2 mt-4">
                          <div className="row">
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="forextab2 mt-4">
                          <div className="row">
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-3">
                              <div className="article1">
                                <a href="#" className="card">
                                  <h6>
                                    Indicator analysis: Daily review of GBP/USD
                                    on January 3, 2022
                                  </h6>
                                  <p>Stefan Doll</p>
                                  <p>
                                    <small>2 hour 19 minutes ago </small>{" "}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-2">
                  <div className="marketanlysec3 mt-4">
                    <div className="d-flex exchnage justify-content-between">
                      <div className="exchnage1">
                        <h6>Exchange rates</h6>
                      </div>
                    </div>
                    <div ref={exchangerate}></div>
                  </div>
                  <div className="tradingins mt-3">
                    <h6 className="text-center">Trading Instruments</h6>
                    <p className="text-center">
                      The instruments for successful online Forex trading
                    </p>

                    <ul className="traditems d-block justify-content-center align-items-center">
                      <a href="https://crm.milliva.com/opendemoaccount">
                        <li>
                          <img
                            src={require("../../assets/images/mtdemoicon1.png")}
                            alt="logo"
                            className="img-fluid mx-2"
                          />
                          Open Demo Account
                        </li>
                      </a>
                      <Link to="/mt5">
                        <li>
                          <img
                            src={require("../../assets/images/metatradeicon1.png")}
                            alt="logo"
                            className="img-fluid mx-2"
                          />
                          Trading Platform
                        </li>
                      </Link>
                      <Link to="/pipcalc">
                        <li>
                          <img
                            src={require("../../assets/images/tradecalc.png")}
                            alt="logo"
                            className="img-fluid mx-2"
                          />
                          Trade calculator
                        </li>
                      </Link>
                      <Link to="/currency">
                        <li>
                          <img
                            src={require("../../assets/images/mtanalyicon1.png")}
                            alt="logo"
                            className="img-fluid mx-2"
                          />
                          Assets
                        </li>
                      </Link>
                      <Link to="/tradingfee">
                        <li>
                          <img
                            src={require("../../assets/images/mtindicatoricon1.png")}
                            alt="logo"
                            className="img-fluid mx-2"
                          />
                          Trading Fees
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Marketanalytical;
