import React from 'react'
import "../../assets/css/royal.css";
import Carousels from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useCountUp } from "react-countup";
import { Link } from 'react-router-dom';
const LoyaltyProgram = () => {

  const responsive3 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useCountUp({
    ref: "counter",
    end: 80000,
    suffix: "+",
    enableScrollSpy: true,
    scrollSpyDelay: 500,
  });
  useCountUp({
    ref: "counter1",
    end: 1200,
    suffix: "+",
    enableScrollSpy: true,
    scrollSpyDelay: 500,
  });
  useCountUp({
    ref: "counter2",
    end: 3600,
    suffix: "+",
    enableScrollSpy: true,
    scrollSpyDelay: 500,
  });
  useCountUp({
    ref: "counter3",
    end: 16,
    suffix: "+",
    enableScrollSpy: true,
    scrollSpyDelay: 500,
  });

  return (
    <div>
      <div className='loyalprogram'>
        <div className='container'>
          <div className='rewardssection'>
            <div className='row align-items-center'>
              <div className='col-md-12 col-lg-7'>
                <h1 className='mb-3'>Milliva LTD loyalty program</h1>
                <p className='mb-3'>Get privileges for being an Milliva client</p>
                <Link to="https://crm.milliva.com/client/register/6169896c79fd7"><button className='btn'>Claim Now</button></Link>
              </div>
              <div className='col-md-12 col-lg-5'>
                <img
                  src={require("../../assets/images/claimsimg.png")}
                  alt="logo"
                  className="img-fluid mb-3"
                />
              </div>
            </div>
          </div>
          <div className='loyaltycontent mt-4'>
            <h2 className='text-center mb-3'>Welcome to the Investor & Trader Loyalty Program</h2>
            <p className='text-center mb-3'>Don't let this opportunity slip through your fingers! Act now to reach your goals with this limited-time offer!</p>
            <h5 className='text-center mb-3'>How to get a Rewards</h5>

            <div className='row mt-4'>
              <div className='col-md-12 col-lg-4 text-center'>
                <div className='getrewards'>
                  <div className="rewardimg">
                    <img
                      src={require("../../assets/images/signupicon2.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className='rewardcont'>
                    <h5 className='mb-2'>Register</h5>
                    <p>Enter your details & Upload your documents</p>
                  </div>


                </div>


              </div>
              <div className='col-md-12 col-lg-4 text-center'>
                <div className='getrewards'>
                  <div className="rewardimg">
                    <img
                      src={require("../../assets/images/depositicon1.png")}
                      alt="logo"
                      className="img-fluid  mb-3"
                    />
                  </div>
                  <div className='rewardcont'>
                    <h5 className='mb-2'>Deposit</h5>
                    <p> Deposit Minimum of $500,Payment Mode Crypto and local bank wire Transfer</p>
                  </div>
                </div>


              </div>
              <div className='col-md-12 col-lg-4 text-center'>
                <div className='getrewards'>
                  <div className="rewardimg">
                    <img
                      src={require("../../assets/images/claimrewards.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className='rewardcont'>
                    <h5 className='mb-2'>Claim Your Rewards</h5>
                    <p> Level up to the highest status and reach out for the most luxurious prizes!</p>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className='loyal-rewards mt-5'>
            <h2 className='text-center'>Loyalty Rewards</h2>


            <div className="row mt-5">
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/millivatshirt.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category ">Milliva LTD T-Shirt & Gift
                    </h5>
                    <p className="card-description"> <span className='deamt'>$500</span> Deposit Amount
                    </p>

                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/smartmilliva.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category "> Fastrack Smart Watch

                    </h5>
                    <p className="card-description"> <span className='deamt'>$3,000</span> Deposit Amount

                    </p>

                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/smartwatchpower.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category ">Fastrack Smart Watch & Mi Power Bank


                    </h5>
                    <p className="card-description"> <span className='deamt'>$5,000</span> Deposit Amount


                    </p>

                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/Samsung 5G Phone.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category "> Samsung Galaxy M14 5G


                    </h5>
                    <p className="card-description"> <span className='deamt'>$15,000</span> Deposit Amount


                    </p>

                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/Samsung Tab.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category ">Samsung Galaxy Tab S6 Lite

                    </h5>
                    <p className="card-description"> <span className='deamt'>$30,000</span> Deposit Amount

                    </p>

                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/Samsung Laptop.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category ">Samsung Galaxy Book 2 Core i5 12th Gen


                    </h5>
                    <p className="card-description"> <span className='deamt'>$50000 </span>Deposit Amount


                    </p>

                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/MacBook Air M2.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category ">MacBook Air M2 256GB



                    </h5>
                    <p className="card-description"><span className='deamt'>$1,00,000</span> Deposit Amount



                    </p>

                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="card card-profile">
                  <div className="card-image">
                    <img
                      src={require("../../assets/images/macdubai.png")}
                      alt="logo"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <div className="table">
                    <h5 className="category ">4 Days Dubai Trip <span className='iphoneor'>or</span> iPhone 15 Pro Max



                    </h5>
                    <p className="card-description"><span className='deamt'>$2,00,000</span> Deposit Amount




                    </p>

                  </div>
                </div>
              </div>
            </div>
            {/* <div className='clients-des'>
              <h2 className='text-center mb-3'>Begin Your Journey to Success Now!</h2>

              <div className='row'>
                <div className='col-md-12 col-lg-6'>
                  <div className='clients  d-flex align-items-center'>
                    <div className='climg'></div>
                    <div className='clno'>
                      <p>Milliva’s LTD Client </p>
                      <h4>80,000+</h4>

                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                  <div className='clients  d-flex align-items-center'>
                    <div className='climg'></div>
                    <div className='clno'>
                      <p>Instruments</p>
                      <h4>1200+</h4>


                    </div>
                  </div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-md-12 col-lg-6'>
                  <div className='clients d-flex align-items-center'>
                    <div className='climg'></div>
                    <div className='clno'>
                      <p>Introducing Brokers </p>

                      <h4>3600+
                      </h4>

                    </div>
                  </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                  <div className='clients d-flex align-items-center'>
                    <div className='climg'></div>
                    <div className='clno'>
                      <p>Offices & Branches</p>


                      <h4>16+</h4>


                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="milliva-rated">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3">
                    <h3 id="counter"></h3>
                    <p>MILLIVA LTD GROUP CLIENTS</p>
                  </div>
                  <div className="col-lg-3">
                    <h3 id="counter1"></h3>

                    <p>TRADING INSTRUMENTS</p>
                  </div>
                  <div className="col-lg-3">
                    <h3 id="counter2"></h3>
                    <p>INTRODUCING BROKERS</p>
                  </div>
                  <div className="col-lg-3">
                    <h3 id="counter3"></h3>
                    <p>OFFICES & BRANCHES</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="fastsecure mt-3">
              <div className="fastsectrade brand-home">
                <div className="container">
                  <h2 className="text-center mb-3">Fast and Secure Trading</h2>
                  <p>
                    With easy to make a deposit into your trading account you will
                    be in the markets in minutes and when you choose a law or no
                    fee option you will have more left to trade with.
                  </p>
                  <div className="accslider">
                  
                    <div className="accsli1">
                      <img
                        src={require("../../assets/images/home/tether-ico.png")}
                        alt="logo"
                        className="img-fluid m-auto d-block  "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className='promotionrules mt-5 mb-5'>
                <div className='container'>


                  <h2 className='text-center'>Terms & Conditions
                  </h2>
                  <ul className='rulespromo'>
                    <li> <i class="fa fa-check mx-2" aria-hidden="true"></i> Make sure you use this offer with this limited-time offer (The offer Starts From 26th Jan 2024 to 31th March 2024) ( 45 days)

                    </li>
                    <li><i class="fa fa-check mx-2" aria-hidden="true"></i>All rewards will be calculated by the volume of business done From November 1 – December 15, 2023. (To get your rewards, please maintain your business volume for a minimum of 1 month.)
                    </li>



                  </ul>
                  <div className="block-item-text">
                    <input type="checkbox" hidden className="read-more-state" id="read-more" />
                    <div className="read-more-wrap">

                      <p className="read-more-target">
                        <h5>Who can win?</h5>
                        <p>Every Investor and trader will be rewarded based on your total Deposit Amount.



                        </p>
                        <h5>INVESTOR & Trader Promotion Offer
                        </h5>
                        <ul className='winpromo mx-2'>
                          <p> $500 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a stylish Milliva LTD T-shirt and a warm welcome gift. To qualify, simply reach a $500 target within 45 days.
                          </li>




                        </ul>
                        <ul className='winpromo mx-2'>
                          <p>  $3,000 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a stylish Smartwatch (Flipkart). To qualify, simply reach a $3,000 target within 45 days.
                          </li>

                        </ul>
                        <ul className='winpromo mx-2'>
                          <p>  $5,000 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a stylish Smartwatch & Powerbank (Flipkart & Mi power bank). To qualify, simply reach a $5,000 target within 45 days.
                          </li>

                        </ul>
                        <ul className='winpromo mx-2'>
                          <p> $15,000 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a Samsung 5G Phone (Samsung Galaxy M14 5G). To qualify, simply reach a $15,000 target within 45 days.
                          </li>

                        </ul>
                        <ul className='winpromo mx-2'>
                          <p> $30,000 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a Samsung Tab (Samsung Galaxy Tab S6 Lite). To qualify, simply reach a $30,000 target within 45 days.
                          </li>

                        </ul>
                        <ul className='winpromo mx-2'>
                          <p>$50,000 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a Samsung Laptop (Samsung Galaxy Book 2 Core i5 12th Gen). To qualify, simply reach a $50,000 target within 45 days.
                          </li>

                        </ul>
                        <ul className='winpromo mx-2'>
                          <p>$1,00,000 Deposit Amount


                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a MacBook Air M2  (MacBook Air M2 256GB). To qualify, simply reach a $1,00,000 target within 45 days.
                          </li>

                        </ul>
                        <ul className='winpromo mx-2'>
                          <p>$2,00,000 Deposit Amount

                          </p>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Meet the criteria to unlock exciting rewards, such as a 4-day Dubai Trip or iPhone 15ProMax. To qualify, simply reach a $50,000 target within 45 days.

                          </li>

                        </ul>



                        <p><span>Note:</span> (After this offer ends, you have to  maintain the same volume for the next 3 months)</p>






                        <h5>Delivery</h5>
                        <ul className='deliveryli'>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>The only client who registered for a gift can be the recipient.

                          </li>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Provide your official address and contact information while claiming. We are only responsible if the recipient provides the correct delivery address and contact details.



                          </li>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>If the recipient does not respond to any calls from delivery services, your gifts will be retrieved and cancelled. Your gift will be restored to Milliva LTD. You can retain it when possible.


                          </li>
                          <li><i class="fa fa-check mx-2" aria-hidden="true"></i>To claim rewards, you can contact us through mail: Support@milliva.com  and WhatsApp support Team +971 5257 54887. Our Milliva LTD team will respond to you within 48 hours.

                          </li>

                        </ul>

                      </p>
                    </div>

                    <label htmlFor="read-more" className="read-more-trigger_closed">Read More</label>
                    <label htmlFor="read-more" className="read-more-trigger_opened">Read Less</label>


                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoyaltyProgram