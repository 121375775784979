import React,{useEffect} from "react";
import "../../assets/css/faq.css";

import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineFileProtect } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { AiOutlineBank } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet, MdAllInclusive } from "react-icons/md";
import { RiLuggageDepositLine } from "react-icons/ri";
import { BiDollarCircle } from "react-icons/bi";
import { IoMdSwap } from "react-icons/io";
import { MdSignalCellularAlt } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { BsCashCoin } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { FcNeutralTrading } from "react-icons/fc";
import { MdOutlineCalculate } from "react-icons/md";
import { FaUniversity } from "react-icons/fa";
import { SiPlatformdotsh } from "react-icons/si";
import { MdPeopleOutline } from "react-icons/md";
import { RiHandCoinLine } from "react-icons/ri";

const Faq = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
          top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <div className="Faq">
        <section className="faq-banner">
          <div className="faqcont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center mt-5">
                <h1 className="mt-5">Frequently Asked Questions (FAQ)</h1>
                  <p className=" mb-3">
                    Our FAQ section provides solutions to frequently asked questions about Milliva LTD
                  </p>
                </div>
                <div className="col-md-12 col-lg-6 mt-5">
                  <img
                    src={require("../../assets/images/faq/faqbanner.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faqmill">
          <div className="faqfirst">
            <div className="container ">
              <div className="row mt-4 ">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <AiOutlineUserAdd />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2"> General</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <AiOutlineFileProtect />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Registration
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <AiOutlineUserSwitch />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">Trading</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <AiOutlineBank />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Trading Service
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <MdOutlineAccountBalanceWallet />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Placing Trade
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <RiLuggageDepositLine />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Trading Account
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <FcNeutralTrading />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Trading Symbol
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
                       <Link to="/faq2">
                        <div className="mb-2">
                          <FaUniversity />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Trading account information
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <MdOutlineCalculate />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Calculations
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
                       <Link to="/faq2">
                        <div className="mb-2">
                          <RiHandCoinLine />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Deposits and Withdrawals
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='col-12 col-md-6 col-lg-3'>
    <div className='bitcoiva faq box2'>
      <div className=''>

        <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
          <div className="mb-2"><RiLuggageDepositLine /></div>
          <h3 className="h3-md fw-5 txt-orange mb-2">Making Deposit</h3>

          <a className='btn-theme bg-orange mt-3'><Link to="/faq2"> Get In Touch</Link></a>




        </div>

      </div>
    </div>
  </div> */}

                {/* <div className='col-12 col-md-6 col-lg-3'>
    <div className='bitcoiva faq box2'>
      <div className=''>

        <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
          <div className="mb-2"><RiLuggageDepositLine /></div>
          <h3 className="h3-md fw-5 txt-orange mb-2">Withdrawals</h3>

          <a className='btn-theme bg-orange mt-3'><Link to="/faq2"> Get In Touch</Link></a>




        </div>

      </div>
    </div>
  </div> */}

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <MdPeopleOutline />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">
                          Partnership Program
                        </h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="bitcoiva faq box2">
                    <div className="">
                      <div className="widget-wrap text-center bg-sky rounded py-5 pb-5 mb-5 plateform-ico">
                      <Link to="/faq2">
                        <div className="mb-2">
                          <SiPlatformdotsh />
                        </div>
                        <h3 className="h3-md fw-5 txt-orange mb-2">Platform</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Faq;
