import React, { useEffect, useState } from 'react'
import '../../assets/css/cart.css'
import { useNavigate, useParams } from 'react-router-dom'
import ProductsList from '../../assets/documents/ProductsList'
import { ToastContainer, toast } from 'react-toastify';
import Common from '../../Helper/Encrypt'
import { NotificationManager } from 'react-notifications';


const Cart = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [data, SetData] = useState({})
    const [showButton, SetshowButton] = useState(true)
    const [decryptData, SetdecryptData] = useState(false)

    async function decryptFun() {
        SetdecryptData(await Common.DecodeText(id))
    }
    useEffect(() => {
        decryptFun()
        const ProductDetails = ProductsList.find((e) => e.productId == decryptData)
        SetData(ProductDetails)
    }, [data, id, decryptData])


    useEffect(() => {
        ShowButtonFuction()
    }, [id, decryptData])

    async function ShowButtonFuction() {
        let getCartDetails = await localStorage?.getItem('cartDetails')
        getCartDetails = JSON?.parse(getCartDetails)
        const existscheck = await getCartDetails?.find(e => e.productId == decryptData)
        let showButtonVerify = (existscheck) ? false : true
        SetshowButton(showButtonVerify)
    }


    async function AddCarts() {
        try {
            const cartDetails = []
            let getCartDetails = await localStorage.getItem('cartDetails')
            getCartDetails = JSON.parse(getCartDetails)
            if (getCartDetails?.length > 0) {
                const existscheck = await getCartDetails?.find(e => { return e.productId == data.productId })
                if (!existscheck) {
                    getCartDetails.push(data)
                    SetshowButton(false)
                    await localStorage.setItem('cartDetails', JSON.stringify(getCartDetails))
                    NotificationManager.success('Add to cart Successfully', "Success");
                    // toast.success('Add to cart Successfully')
                } else {
                    const cartData = await getCartDetails?.filter(e => { return e.productId !== data.productId })
                    SetshowButton(true)
                    await localStorage.setItem('cartDetails', JSON.stringify(cartData))
                    NotificationManager.success('Remove to cart Successfully', "Success");
                    // toast.success('Remove to cart Successfully')
                }

            } else {
                cartDetails.push(data)
                await localStorage.setItem('cartDetails', JSON.stringify(cartDetails))
                NotificationManager.success('Add to cart Successfully', "Success");
                SetshowButton(false)
                // toast.success('Add to cart Successfully')
            }
        } catch (error) {
            console.log(error)
            toast.error('Some error occured')
        }
    }
    async function BuyNow() {
        try {

            navigate(`/checkout/${encodeURIComponent(id)}`)
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div className='cart-section mb-5'>
            <div className='container'>
                <div className='add-cart'>
                    <img
                        src={require("../../assets/images/webdevelopment.png")}
                        alt="logo"
                        className="img-fluid headset"
                    />
                    <div id="cart-text">

                        <h3>{data?.tittle}</h3>

                        <h1>{data?.price}</h1>

                        <button className="add-to-cart" onClick={() => { /* navigate('/checkout'); */ AddCarts() }}>{showButton ? 'Add to Cart' : 'Remove to cart'}</button>
                        <div className="description-cart">
                            <h5>Description</h5>
                            <p>{data?.descrption}</p>
                        </div>
                        <div id="buttons" className='cart-button' onClick={() => { BuyNow() }}>
                            <button className="add-to-cart-2">
                                <i class="fa fa-shopping-cart" aria-hidden="true" ></i>Buy Now
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Cart