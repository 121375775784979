import { AES, enc, } from 'crypto-js'

const Key = 'WzFtapoYCdYohdyy'
const iv = 'wBsUkAaPmdsISlRs'

const Common = {
    EncodeText: function (text) {
        const returnValue = new Promise(function (reslove, reject) {
            try {
                let EnTxT = AES.encrypt(text, Key, { iv: iv })
                if (EnTxT) {
                    reslove(EnTxT?.toString())
                }
                else {
                    reject(false)
                }
            } catch (error) {
                reject(error)
            }
        })
        return returnValue;
    },
    DecodeText: function (text) {
        const returnValue = new Promise(function (reslove, reject) {
            try {
                let EnTxT = AES.decrypt(text, Key, { iv: iv })
                if (EnTxT) {
                    reslove(EnTxT?.toString(enc.Utf8))
                }
                else {
                    reject(false)
                }
            } catch (error) {
                reject(error)
            }
        })
        return returnValue;
    }

}

export default Common;