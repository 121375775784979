import React,{useEffect} from "react";
import "../../assets/css/negative.css";
import { Link } from "react-router-dom";
const Negativebb = () => {
    useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
          top: 0,
           
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <div className="Negativebalance">
        <section className="Negative">
          <div className="n-balance">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                <h1 className=" mb-2">Negative Balance Protection</h1>
                  <p className=" mb-3">
                    With Milliva LTD, your account will be completely safe with negative balance protection
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/negativeb/negative-bann1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ====================================banner next======================================= */}
        <section className="nb-balance mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="banner-next">
                  <p>
                    Milliva LTD is offering negative balance protection to all
                    clients is a primary goal for enhancing their trading
                    experiences: Our risk management strategy makes sure the
                    client does not suffer a loss greater than their initial
                    investment. If the stop-out causes the balance to go
                    negative, Milliva LTD makes up the difference by bringing the
                    account balance to zero.
                  </p>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="user-avatar">
                      <img
                        src={require("../../assets/images/negativeb/user-avatar.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <div className="message-1">
                      <p>
                        <small>
                          Is it feasible for me to suffer a loss greater than my
                          initial investment? What if there is a negative
                          account balance?
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div class="col-lg-3 col-md-3 col-sm-3">
                
                </div> */}
                  <div className="col-lg-9 col-md-9 col-sm-9">
                    <div className="message-2">
                      <p>
                        <small>
                          Milliva LTD offers negative balance protection, Therefore,
                          whenever your balance falls below zero, we reset it to
                          zero.
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <div className="user-logo mt-2">
                      <img
                        src={require("../../assets/images/negativeb/user-logo.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*============================================nb-cards=================================  */}
        <section className="nb-cards mt-5 pt-4">
          <div className="n-balance-line1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="nb-card-images">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12 ">
                        <div className="nb-image1 card">
                          <img
                            src={require("../../assets/images/negativeb/nb-001.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <p>CREDIT</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="nb-image1 card">
                          <img
                            src={require("../../assets/images/negativeb/nb-002.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <p>Beyond the initial deposit, there is no loss</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="nb-image1 card">
                          <img
                            src={require("../../assets/images/negativeb/nb-003.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <p>Reappraise</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p className="nb-text">
                    Your risk will only be as much as the money that has been
                    deposited into your account, according to Milliva's LTD
                    guarantee. Please be aware that the customer's debt is not
                    covered by negative balance protection. As a result, our
                    clients are shielded from losses that go beyond their
                    initial investment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="n-balance-line2 mt-5 pt-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p className="nb-text">
                    When large economic events take place or when abrupt market
                    changes have a significant impact on asset values, your
                    account balance is likely to be negative in certain
                    situations. A client may lose equity due to extreme
                    volatility and pricing discrepancies. The balance is then
                    reset to zero by Milliva LTD. Here are some preventative steps
                    you may take to keep your account balance from being
                    negative:
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 ">
                  <div className="nb-card-images">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="nb-image1 card">
                          <img
                            src={require("../../assets/images/negativeb/nb-004.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <p>Prevent stop loss </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="nb-image1 card">
                          <img
                            src={require("../../assets/images/negativeb/nb-005.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <p>Utilize leverage sensibly </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="nb-image1 card">
                          <img
                            src={require("../../assets/images/negativeb/nb-006.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <p>Observe your trade volume.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="trade getmt5 mt-4 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center">Get Started</h2>
              <p class="getapara">Get start trade anywhere, anytime. We have everything you need: friendly customer support, the latest technology, and multiple account types to find the trades.</p>
              <div className="row mt-5 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images//algoimg/singupicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h4 className="my-1 mb-3">Signup</h4>
                        <p className="mb-0 font-13">
                          Enter your details and setup an account
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/algoimg/verifyicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h4 className="my-1 mb-3">Verify</h4>
                        <p className="mb-0 font-13">
                          Upload your documents and get verified instantly
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/algoimg/depositicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h4 className="my-1 mb-3">Deposit</h4>
                        <p className="mb-0 font-13">
                        Deposit Minimum as $100
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/algoimg/tradeicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h4 className="my-1 mb-3">Trade</h4>
                        <p className="mb-0 font-13">
                          Start trading any instrument of your choice and make
                          profits
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Live Account
                          </button>
                        </a>
                      </div>
                      <div className="opendemobtn">
                        <a href="https://crm.milliva.com/opendemoaccount">
                          <button type="button" className="btn btn-primary">
                            Open Demo Account
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                    <img
                      src={require("../../assets/images/algoimg/getstartimg.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Negativebb;
