

const ProductsList = [
    {
        productId: 1,
        imgurl: 'mobile-appimg',
        tittle: 'Mobile App',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 2,
        imgurl: 'static-website',
        tittle: 'Dynamic websites',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 3,
        imgurl: 'dynamic-webpage',
        tittle: 'Static Websites',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 4,
        imgurl: 'digitamarketing',
        tittle: 'Digital Marketing',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 5,
        imgurl: 'gaming-website',
        tittle: 'Gaming Websites',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 6,
        imgurl: 'seo-developement',
        tittle: 'SEO Development',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 7,
        imgurl: 'ecommerce-website',
        tittle: 'Ecommerce websites',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
    {
        productId: 8,
        imgurl: 'mlmwebsite',
        tittle: 'MLM Websites',
        price: '$59',
        descrption: 'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.'
    },
]

export default ProductsList;
