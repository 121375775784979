import React,{useEffect} from "react";
import "../../assets/css/deposit.css";
import { Link } from "react-router-dom";
const Depositwi = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <>
        <div className="deposit-value">
          <section className="mt5trade">
            <div className="mt5cont">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 ">
                    <h1 className=" mb-2">Make Deposits And Withdrawals At Your Convenience
</h1>
                    <p className=" mb-3">
                    Enjoy the full benefits of trading the market with deposits and withdrawals from your account, with no fees or commissions.
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/mani/despoitwithdraw.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="deposit-withdraw-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="deposit-content1 mt-3 py-3 text-center">
                    <h2>No Deposit Fees Fast Withdrawals</h2>
                    <p className="mt-3">
                      We streamline the deposit and withdrawal processes so you
                      have more time to concentrate on the markets and your
                      upcoming trading opportunity.There are many other ways to
                      transfer money, including using bank cards, e-wallets, and
                      bank wire. It is always Milliva LTD traders' interests that come first. We aim to provide you with a trading environment that will not restrict your profitability by charging you fees from your bank account and/or credit card. Our zero fees policy applies to all deposits starting at $100, as well.				
                    </p>
                  </div>
                </div>
              </div>
              <div className="row deposit-fees-fast">
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="deposit-content2 text-center mt-3 py-4">
                    <img
                      src={require("../../assets/images/mani/dep-safety.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <h4 className="mt-2">Safety</h4>
                    <p>
                      For your peace of mind, your money is stored apart from
                      ours in reputable banks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="deposit-content2 text-center mt-3 py-4">
                    <img
                      src={require("../../assets/images/mani/dep-regulation.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <h4 className="mt-2">Regulation</h4>
                    <p>
                      There are different jurisdictions that regulate us. So
                      that you may unwind, we place your money in reputable
                      banks.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="deposit-content2 text-center mt-3 py-4">
                    <img
                      src={require("../../assets/images/mani/dep-choice.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <h4 className="mt-2">choice</h4>
                    <p>
                      Local banking is just one of the many payment options we
                      provide.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="deposit-payment mt-4 text-center py-3">
                    <h2>
                      Explore our most popular deposit and withdrawal payment
                      methods
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="deposit-payment1 mt-3 py-3">
                    {/* Tabs navs */}
                    <ul
                      className="nav nav-tabs mb-3 nav-justified"
                      id="ex1"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="ex1-tab-1"
                          data-bs-toggle="tab"
                          href="#ex1-tabs-1"
                          role="tab"
                          aria-controls="ex1-tabs-1"
                          aria-selected="true"
                        >
                          Crypto Wallet
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="ex1-tab-2"
                          data-bs-toggle="tab"
                          href="#ex1-tabs-2"
                          role="tab"
                          aria-controls="ex1-tabs-2"
                          aria-selected="false"
                        >
                          Via Wallet
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="ex1-tab-3"
                          data-bs-toggle="tab"
                          href="#ex1-tabs-3"
                          role="tab"
                          aria-controls="ex1-tabs-3"
                          aria-selected="false"
                        >
                          Bank Cards
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="ex1-tab-4"
                          data-bs-toggle="tab"
                          href="#ex1-tabs-4"
                          role="tab"
                          aria-controls="ex1-tabs-4"
                          aria-selected="false"
                        >
                          UPI Wallet
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          id="ex1-tab-5"
                          data-bs-toggle="tab"
                          href="#ex1-tabs-5"
                          role="tab"
                          aria-controls="ex1-tabs-5"
                          aria-selected="false"
                        >
                          Local Transfer
                        </a>
                      </li>
                    </ul>
                    {/* Tabs navs */}
                    {/* Tabs content */}
                    <div className="tab-content mt-4" id="ex1-content">
                      <div
                        className="tab-pane fade show active"
                        id="ex1-tabs-1"
                        role="tabpanel"
                        aria-labelledby="ex1-tab-1"
                      >
                        <div className="deposit-payment-new">
                          <div
                            className="accordion"
                            id="accordionFlushExampleX"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingOneX"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOneX"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOneX"
                                >
                                  <img
                                    src={require("../../assets/images/mani/btc.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>BITCOIN</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOneX"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOneX"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                  <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTwoX"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwoX"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwoX"
                                >
                                  <img
                                    src={require("../../assets/images/mani/eth.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>ETHEREUM</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwoX"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwoX"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingThreeX"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThreeX"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThreeX"
                                >
                                  <img
                                    src={require("../../assets/images/mani/tether.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>TETHER USDT (TRC20)</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThreeX"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingThreeX"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td> USDT (TRC20)</td>
                                            <td> USDT (TRC20)</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingFourX"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFourX"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFourX"
                                >
                                  <img
                                    src={require("../../assets/images/mani/tether.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>TETHER USDT (ERC20)</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFourX"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFourX"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td> USDT (ERC20)</td>
                                            <td> USDT (ERC20)</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="ex1-tabs-2"
                        role="tabpanel"
                        aria-labelledby="ex1-tab-2"
                      >
                        <div className="deposit-payment-new">
                          <div
                            className="accordion"
                            id="accordionFlushExampleX"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTwoXX"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwoXX"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwoXX"
                                >
                                  <img
                                    src={require("../../assets/images/mani/skril.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>SKRILL</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwoXX"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwoXX"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingNew2"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseNew2"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseNew2"
                                >
                                  <img
                                    src={require("../../assets/images/mani/netler.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>NETELLER</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseNew2"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingNew2"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingNew3"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseNew3"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseNew3"
                                >
                                  <img
                                    src={require("../../assets/images/mani/perfectmoney.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>PERFECT MONEY</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseNew3"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingNew3"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="ex1-tabs-3"
                        role="tabpanel"
                        aria-labelledby="ex1-tab-3"
                      >
                        <div className="deposit-payment-new">
                          <div
                            className="accordion"
                            id="accordionFlushExampleX"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingNew3X"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseNew3X"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseNew3X"
                                >
                                  <img
                                    src={require("../../assets/images/mani/visa.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>VISA</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseNew3X"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingNew3X"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingNine"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseNine"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseNine"
                                >
                                  <img
                                    src={require("../../assets/images/mani/master.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>MASTER CARD</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseNine"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingNine"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                           <td>Currency</td>
                                            <td>USD, EUR, GBP</td>
                                            <td>USD, EUR, GBP</td>
                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>3 USD / 2EUR / 2GBP</td>
                                          </tr>
                                          <tr>
                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>
                                          </tr>
                                          <tr>
                                            <td>Minimum Amount</td>
                                            <td>5 USD/EUR/GBP</td>
                                            <td>NA</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTen"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTen"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTen"
                                >
                                  <img
                                    src={require("../../assets/images/mani/maestro.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>MAESTRO</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTen"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTen"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD, EUR, GBP</td>
                                            <td>USD, EUR, GBP</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>3 USD / 2 EUR / 2 GBP</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>5 USD/EUR/GBP</td>
                                            <td>NA</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="ex1-tabs-4"
                        role="tabpanel"
                        aria-labelledby="ex1-tab-4"
                      >
                        <div className="deposit-payment-new">
                          <div
                            className="accordion"
                            id="accordionFlushExampleX"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingNew3XX"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseNew3XX"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseNew3XX"
                                >
                                  <img
                                    src={require("../../assets/images/mani/gpay.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>GOOGLE PAY</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseNew3XX"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingNew3XX"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD</td>
                                            <td>USD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTwelve"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwelve"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwelve"
                                >
                                  <img
                                    src={require("../../assets/images/mani/amazonpay.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>AMAZON PAY</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwelve"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwelve"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD, EUR, GBP</td>
                                            <td>USD, EUR, GBP</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>3 USD / 2 EUR / 2 GBP</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>5 USD/EUR/GBP</td>
                                            <td>NA</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingThirtin"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseThirtin"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseThirtin"
                                >
                                  <img
                                    src={require("../../assets/images/mani/bhim.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>BHIM PAY</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseThirtin"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingThirtin"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                    <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD, EUR, GBP</td>
                                            <td>USD, EUR, GBP</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>3 USD / 2 EUR / 2 GBP</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>5 USD/EUR/GBP</td>
                                            <td>NA</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingFourtin"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFourtin"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFourtin"
                                >
                                  <img
                                    src={require("../../assets/images/mani/freecharge.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>FREECHARGE PAY</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFourtin"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFourtin"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                    <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>USD, EUR, GBP</td>
                                            <td>USD, EUR, GBP</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>3 USD / 2 EUR / 2 GBP</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>5 USD/EUR/GBP</td>
                                            <td>NA</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="ex1-tabs-5"
                        role="tabpanel"
                        aria-labelledby="ex1-tab-5"
                      >
                        <div className="deposit-payment-new">
                          <div
                            className="accordion"
                            id="accordionFlushExampleX"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingFiftin"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseFiftin"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseFiftin"
                                >
                                  <img
                                    src={require("../../assets/images/mani/UAE.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>UAE</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseFiftin"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingFiftin"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>AED</td>
                                            <td>AED</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingSixtin"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseSixtin"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseSixtin"
                                >
                                  <img
                                    src={require("../../assets/images/mani/malaysia.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>MALAYSIA</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseSixtin"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingSixtin"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>MYR</td>
                                            <td>MYR</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingSeventin"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseSeventin"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseSeventin"
                                >
                                  <img
                                    src={require("../../assets/images/mani/singapore.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>SINGAPORE</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseSeventin"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingSeventin"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>SGD</td>
                                            <td>SGD</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingEightin"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseEightin"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseEightin"
                                >
                                 <img
                                    src={require("../../assets/images/mani/india.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>INDIA</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseEightin"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingEightin"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>INR</td>
                                            <td>INR</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>10$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-headingTwenty"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseTwenty"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseTwenty"
                                >
                                  <img
                                    src={require("../../assets/images/mani/england.png")}
                                    alt="logo"
                                    className="img-fluid"
                                  />
                                  <span>ENGLAND</span>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseTwenty"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingTwenty"
                                data-bs-parent="#accordionFlushExampleX"
                              >
                                <div className="accordion-body">
                                <div className="row">
                                    <div class="table-responsive ">
                                      <table class="table table-borderless">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Deposit</th>
                                            <th scope="col">Withdraw</th>


                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>

                                            <td>Currency</td>
                                            <td>GBP</td>
                                            <td>GBP</td>


                                          </tr>
                                          <tr>
                                            <td>Fees/Commission</td>
                                            <td>No Commission</td>
                                            <td>No Commission</td>

                                          </tr>
                                          <tr>

                                            <td>Processing Time</td>
                                            <td>Within a Hour</td>
                                            <td>Within a Day</td>

                                          </tr>
                                          <tr>

                                            <td>Minimum Amount</td>
                                            <td>100$</td>
                                            <td>100$</td>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Tabs content */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="make-deposit">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="make-deposit1 mt-4 py-3 text-center">
                    <h2>How to make a deposit in 4 simple steps</h2>
                  </div>
                </div>
              </div>
              <div className="row deposit-fees-fast2">
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-deposit2 text-center mt-3 py-3">
                    <img
                      src={require("../../assets/images/mani/deposit-step1.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>Open Milliva LTD and go to the deposits page.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-deposit2 text-center mt-3 py-3">
                    <img
                      src={require("../../assets/images/mani/deposit-step2.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>
                      Select the deposit method of your choice, then click
                      "Deposit."
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-deposit2 text-center mt-3 py-3">
                    <img
                      src={require("../../assets/images/mani/deposit-step3.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>
                      To deposit money into a trading account, select it and
                      fill out the required sections.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-deposit2 text-center mt-3 py-3">
                    <img
                      src={require("../../assets/images/mani/deposit-step4.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>
                      You can now move on to the next page to confirm your
                      deposit information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="make-withdrawal">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="make-withdrawal0 mt-4 text-center">
                    <h2>How to make a widthdraw in 4 simple steps</h2>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-withdrawal1 mt-4 py-3 text-center">
                    <img
                      src={require("../../assets/images/mani/deposit1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>Sign in to Milliva LTD and select the withdraw option.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-withdrawal1 mt-4 py-3 text-center">
                    <img
                      src={require("../../assets/images/mani/deposit2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>Click "Withdraw" after selecting your payment method.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-withdrawal1 mt-4 py-3 text-center">
                    <img
                      src={require("../../assets/images/mani/deposit3.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      Select the Milliva LTD account or wallet from which you want
                      to make a withdrawal.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="make-withdrawal1 mt-4 py-3 text-center">
                    <img
                      src={require("../../assets/images/mani/deposit4.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      Click "Submit" after filling out all required Milliva LTD.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade getmt5 mt-4 pb-5">
            <div className="getsmt5">
              <div className="container">
                <h2 className="text-center">Get Started</h2>
                <p class="getapara">Get start trade anywhere, anytime. We have everything you need: friendly customer support, the latest technology, and multiple account types to find the trades.</p>
                <div className="row mt-5 align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="row">
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images//algoimg/singupicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Signup</h4>
                          <p className="mb-0 font-13">
                            Enter your details and setup an account
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/verifyicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Verify</h4>
                          <p className="mb-0 font-13">
                            Upload your documents and get verified instantly
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/depositicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Deposit</h4>
                          <p className="mb-0 font-13">
                            Deposit an amount as low as $100
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/tradeicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Trade</h4>
                          <p className="mb-0 font-13">
                            Start trading any instrument of your choice and make
                            profits
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center getsbtn mt-5">
                        <div className="openlivebtn">
                          <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                            <button type="button" className="btn btn-primary">
                              Open Live Account
                            </button>
                          </a>
                        </div>
                        <div className="opendemobtn">
                          <a href="https://crm.milliva.com/opendemoaccount">
                            <button type="button" className="btn btn-primary">
                              Open Demo Account
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="getimg">
                      <img
                        src={require("../../assets/images/algoimg/getstartimg.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </div>
  );
};

export default Depositwi;
