import React, { forwardRef } from "react";

const BioCalendar = forwardRef(({ value, onClick }, ref) => (
  <div className="CalendarWrapper" ref={ref} onClick={onClick}>
    <input
      className="form-control"
      value={value}
      placeholder="dd / mm / yyyy"
    />
  </div>
));

export default BioCalendar;
