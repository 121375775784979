import React, { useEffect, useRef } from "react";
import "../../assets/css/assets.css";
import { FaCheck } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";
function Shares() {
  const currencychart = useRef();
  const currencychart1 = useRef();
  const currencychart2 = useRef();
  const currencychart3 = useRef();
  const currencychart4 = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "in",
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: true,
      showFloatingTooltip: false,
      width: "100%",
      height: "660",
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(106, 109, 120, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Forex",
          symbols: [
            {
              s: "NASDAQ:META",
              d: " META PLATFORMS, INC.",
            },
            {
              s: "NASDAQ:TSLA",
              d: "TESLA, INC.",
            },
            {
              s: "NASDAQ:MSFT",
              d: " MICROSOFT CORPORATION",
            },
            {
              s: "NASDAQ:AAPL",
              d: " APPLE INC.",
            },
          ],
          originalTitle: "Forex",
        },
      ],
    });
    currencychart.current.appendChild(script);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      symbols: [["Apple", "AAPL|1D"]],
      chartOnly: false,
      width: "100%",
      height: "400",
      locale: "in",
      colorTheme: "light",
      autosize: false,
      showVolume: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "line",
      color: "rgba(76, 175, 80, 1)",
    });
    currencychart1.current.appendChild(script);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      symbols: [["META PLATFORMS, INC.", "NASDAQ:META|12M"]],
      chartOnly: false,
      width: "100%",
      height: "400",
      locale: "in",
      colorTheme: "light",
      autosize: false,
      showVolume: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "line",
      color: "rgba(76, 175, 80, 1)",
    });
    currencychart2.current.appendChild(script);
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      symbols: [["Microsoft", "MSFT|1D"]],
      chartOnly: false,
      width: "100%",
      height: "400",
      locale: "in",
      colorTheme: "light",
      autosize: false,
      showVolume: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "line",
      color: "rgba(76, 175, 80, 1)",
    });
    currencychart3.current.appendChild(script);
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      symbols: [[" TESLA, INC.", "NASDAQ:TSLA|12M"]],
      chartOnly: false,
      width: "100%",
      height: "400",
      locale: "in",
      colorTheme: "light",
      autosize: false,
      showVolume: false,
      hideDateRanges: false,
      hideMarketStatus: false,
      hideSymbolLogo: false,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "line",
      color: "rgba(76, 175, 80, 1)",
    });
    currencychart4.current.appendChild(script);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <>
        <div className="asset-shares-page">
          <section className="mt5trade">
            <div className="mt5cont">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 ">
                    <h1 className=" mb-2">Share Trading</h1>
                    <p className=" mb-3">
                      Get ultra-fast execution and competitive trading
                      conditions when trading thousands of  Shares
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/mani/shares1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="assets-content-page">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2 col-md-12 col-12 col-sm-12">
                  <div className="product1 markettab pb-5 mb-5 mt-4">
                    <div className="nav-tabs-menu">
                      <ul
                        className="nav nav-tabs d-flex"
                        id="ProductTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="Currecny-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Currecny"
                            type="button"
                            role="tab"
                            aria-controls="Currecny"
                            aria-selected="true"
                          >
                            Shares
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="ProductTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="Currecny"
                        role="tabpanel"
                        aria-labelledby="Currecny-tab"
                      >
                        <div className="curre">
                          <div ref={currencychart}></div>
                          <div className="marketbtn d-block flex-column align-items-center">
                            <Link
                              to="https://crm.milliva.com/client/register/6169896c79fd7"
                              className="optrade btn mb-3"
                            >
                              Open trading account
                            </Link>
                            <Link to="/mt5" className="downtrade btn">
                              Download trading platform
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Crypto"
                        role="tabpanel"
                        aria-labelledby="Crypto-tab"
                      >
                        <div className="curre">
                          <img
                            src={require("../../assets/images/mani/marketchart.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">EURUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.066</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">0.222</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -0.12%{" "}
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">GBPUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.2062</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.3967</a>
                                  </td>
                                  <td className="val4">
                                    <a href="">
                                      -0.22%{" "}
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDJPY</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">130.95</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.36</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.5%
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCHF</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">0.9237</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.765</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.9%
                                      <i className="mx-2 text-success">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCAD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.856</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.011</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.7%
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">AUDUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.258</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.0013</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.8%
                                      <i className="mx-2 text-success">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="marketbtn d-block flex-column align-items-center">
                            <a className="optrade btn mb-3" href="#">
                              Open trading account
                            </a>
                            <a className="downtrade btn" href="#">
                              Download trading platform
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Shares"
                        role="tabpanel"
                        aria-labelledby="Shares-tab"
                      >
                        <div className="curre">
                          <img
                            src={require("../../assets/images/mani/marketchart.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">EURUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.066</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">0.222</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -0.12%{" "}
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">GBPUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.2062</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.3967</a>
                                  </td>
                                  <td className="val4">
                                    <a href="">
                                      -0.22%{" "}
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDJPY</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">130.95</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.36</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.5%
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCHF</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">0.9237</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.765</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.9%
                                      <i className="mx-2 text-success">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCAD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.856</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.011</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.7%
                                      <i className="mx-2 text-danger">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">AUDUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.258</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.0013</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.8%
                                      <i className="mx-2 text-success">
                                        <FaChevronDown />
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="marketbtn d-block flex-column align-items-center">
                            <Link
                              to="https://crm.milliva.com/client/register/6169896c79fd7"
                              className="optrade btn mb-3"
                            >
                              Open trading account
                            </Link>
                            <Link to="/mt5" className="downtrade btn">
                              Download trading platform
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 col-12 col-sm-12">
                  <div className="asset-box1 mt-4 ">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade "
                        id="v-pills-link1"
                        role="tabpanel"
                        aria-labelledby="v-pills-link1-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="currency-assets px-3">
                              <h4>Currency</h4>
                            </div>
                            <div className="currency-asset1 mt-3">
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Trade Major, Minor, And Exotic Currency pairs It
                                is the most liquid market and an
                                exciting place to trade, with a daily trading
                                volume of over $5 trillion. Milliva LTD? It's in our
                                name
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                            <div className="curency-image">
                              <img
                                src={require("../../assets/images/mani/currency1.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            <div className="people-currency py-3">
                              <h4 className="px-3">
                                Key Benefits Of
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  Trading Forex
                                </span>
                              </h4>
                              <p className="mt-3">
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Start with a few dollars or thousands to make
                                the most common choice for all traders.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Massive liquidity equals enormous opportunities.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Trading is possible every day of the week
                                because the market is open 24/5.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            <div className="currency-assets px-3 py-3">
                              <h4>
                                Why Trade Currency
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  Pair With Milliva LTD?
                                </span>
                              </h4>
                            </div>
                            <div className="currency-asset1">
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                We usually offer 0.0 spreads on our most popular
                                account's key FX Pairs, like EURUSD and USDJPY.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                You will always receive the  market price
                                because we complete your deals in milliseconds.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Your money is kept entirely separate from our
                                assets at top-tier banks.
                              </p>
                            </div>
                            <div className="mt-3 py-3 px-3">
                              <h4>
                                How Does 
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  Forex Trading Work?
                                </span>
                              </h4>
                              <p className="mt-3">
                                CFD products are used when trading the FX
                                markets using Milliva LTD. Trading currency pairs
                                always involves purchasing or selling a currency
                                value in a different currency. Both the base
                                currency and the quote currency exist.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-link2"
                        role="tabpanel"
                        aria-labelledby="v-pills-link2-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                            <div className="commodities px-3">
                              <h4>
                                Find Opportunity Biggest
                                <span style={{ color: "#f18c20" }}>
                                  Commodities Markets
                                </span>
                              </h4>
                              <p className="mt-3">
                                Online commodity CFD trading is available with
                                variable leverage and affordable spreads—Trade
                                the world commodity market with Milliva LTD for
                                everything from gold and silver to oil.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="commodities-image">
                              <img
                                src={require("../../assets/images/mani/commodities1.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="commodity-trade mt-2">
                            <h4 className="px-3">
                              Key Benefits Of Trading
                              <span style={{ color: "#f18c20" }}>
                                {" "}
                                Commodities?
                              </span>
                            </h4>
                            <p className="">
                              <i className="altick">
                                <FaCheck />
                              </i>
                              Frequently regarded as potential safe havens.
                            </p>
                            <p className="">
                              <i className="altick">
                                <FaCheck />
                              </i>
                              Beneficial for portfolio diversification.
                            </p>
                            <p className="">
                              <i className="altick">
                                <FaCheck />
                              </i>
                              When there is uncertainty, this is common.
                            </p>
                          </div>
                          <div className="commodity-trade1 mt-3">
                            <h4 className="px-3">
                              Why Trade Commodities
                              <span style={{ color: "#f18c20" }}>
                                With Miliva?
                              </span>
                            </h4>
                            <p className="px-3 mt-3">
                              Trade commodities instruments like gold, silver,
                              and copper with confidence and dependability. We
                              provide our customers with a straightforward and
                              user-friendly trading platform for all of our
                              commodities, packed with cutting-edge technologies
                              to facilitate wiser trading choices.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-link3"
                        role="tabpanel"
                        aria-labelledby="v-pills-link3-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="indices1">
                              <h4 className="px-3">
                                Find Opportunity In
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  Indices Markets.{" "}
                                </span>
                              </h4>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Trade 30+ top indices as CFDs through a
                                reputable,  broker with no
                                commission and extremely low spreads.
                              </p>
                            </div>
                            <div className="indices1">
                              <h4 className="px-3">
                                Key Benefits of
                                <span style={{ color: "#f18c20" }}>
                                  Trading Indices
                                </span>
                              </h4>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Instead of relying solely on the strength of one
                                pair, spread your risk.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Trade at any time, day or night, seven days a
                                week, because the currency market is open 24
                                hours a day.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Available across all trading platforms and
                                accounts.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="indices image">
                              <img
                                src={require("../../assets/images/mani/indices3.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            <div className="indices-work">
                              <h4 className="px-3">
                                Why Trade Indices
                                <span style={{ color: "#f18c20" }}>
                                  With Milliva LTD?
                                </span>
                              </h4>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Trade a currency group's trend instead of
                                opening numerous positions on different currency
                                pairs.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                You may always get assistance with your trading
                                account on Monday through Friday.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Utilize the account with the lowest trading
                                fees, Advantage, to access FX indices on both
                                MT5.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Open a trading account and begin trading FX
                                indices in just a few minutes.
                              </p>
                            </div>
                            <div className="indices-work1">
                              <h4 className="px-3">
                                How Does Indices
                                <span style={{ color: "#f18c20" }}>
                                  Trading Work?
                                </span>
                              </h4>
                              <p className="indi1 d-flex">
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                An FX index, made up of 5 or 6 main pairings,
                                gauges the strength of one currency relative to
                                a group of other essential FX pairs.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                The price changes of these currency pairs
                                directly affect the index's strength.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                The index's value rises when the price of that
                                currency relative to other currencies does.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-link4"
                        role="tabpanel"
                        aria-labelledby="v-pills-link4-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="shares-page">
                              <h4 className="px-3">
                                Find Opportunity In
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  Share Markets.
                                </span>
                              </h4>
                              <p className="px-3">
                                Leverage allows you to have exposure to various
                                shares in Australia, the US, the UK, and
                                Germany. Cheap commissions, high liquidity, and
                                razor-sharp pricing.
                              </p>
                            </div>
                            <div className="shares-page1">
                              <h4 className="px-3">
                                Key Benefits Of
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  Trading Shares
                                </span>
                              </h4>
                              <p className="">
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Own a stake in your preferred business, such as
                                Tesla or Apple.
                              </p>
                              <p className="">
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                An excellent option for long-term investing.
                              </p>
                              <p className="">
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Less dangerous than CFDs because leverage is not
                                used in trading.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="share-image text-center">
                              <img
                                src={require("../../assets/images/mani/share3.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                          </div>

                          <div className="shares-page2">
                            <h4 className="px-3">
                              Why Trade Indices
                              <span style={{ color: "#f18c20" }}>
                                {" "}
                                With Shares
                              </span>
                            </h4>
                            <p className="px-3">
                              Looking for a way to make money on some of the
                              most well-liked stocks without paying
                              commissions? With Milliva LTD, start learning about
                              trading CFDs on shares.
                            </p>
                          </div>
                          <div className="shares-page3">
                            <h4 className="px-3">
                              How Does Share
                              <span style={{ color: "#f18c20" }}>
                                {" "}
                                Trading Work?
                              </span>
                            </h4>
                            <p className="px-3">
                              The buying and selling of stock refer to the
                              ownership of a firm. To turn a profit is the aim.
                              A little portion of a corporation becomes yours
                              after you purchase shares in it. The ups and downs
                              in the company's stock price will affect how much
                              your part is worth. You cannot open sell (short)
                              positions on stocks using our stock trading
                              service, but you are still permitted to sell any
                              shares you currently own in order to potentially
                              make a profit.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-link5"
                        role="tabpanel"
                        aria-labelledby="v-pills-link5-tab"
                      >
                        <div>
                          <h4 className="px-3">
                            Fast, Simple, and reasonably priced. Start your
                            cryptocurrency journey!
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="cryptocurrencies mt-3">
                              <h4 className="px-3">
                                Find Opportunity In
                                <span style={{ color: "#f18c20" }}>
                                  Crypto Markets.
                                </span>
                              </h4>
                              <p className="px-3">
                                Digital currencies known as cryptocurrency CFDs
                                are subject to significant price fluctuations.
                                By trading bitcoin CFDs with Milliva LTD without
                                using a digital wallet or a cryptocurrency
                                exchange, you can profit from this volatility.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                            <div className="crypto-image">
                              <img
                                src={require("../../assets/images/mani/cryptocurrency1.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                            <div className="currency-service">
                              <h4 className="px-3">
                                Key Benefits Of
                                <span style={{ color: "#f18c20" }}>
                                  Trading Crypto
                                </span>
                              </h4>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                With Milliva LTD, you may trade cryptocurrencies
                                without having to pay a commission on any
                                transactions.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                Trade cryptocurrencies using the same MT5
                                trading platform to avoid the hassles of a
                                digital wallet.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                YazhiFX does not charge any fees for withdrawals
                                and deposits. We are here to help you 24/5.
                              </p>
                              <p>
                                <i className="altick">
                                  <FaCheck />
                                </i>
                                With 0% commission and our low spreads, trading
                                cryptocurrency is more economical.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h4 className="px-3">
                                Why Trade Indices
                                <span style={{ color: "#f18c20" }}>
                                  {" "}
                                  With Crypto?
                                </span>
                              </h4>
                              <p className="px-3">
                                Learn about the advantages of trading
                                cryptocurrency CFDs and get a taste of the world
                                of virtual money like Bitcoin, Ethereum, and
                                Litecoin.
                              </p>
                            </div>
                            <div className="mt-3">
                              <h4 className="px-3">
                                How Does Crypto
                                <span style={{ color: "#f18c20" }}>
                                  Trading Work?
                                </span>
                              </h4>
                              <p className="px-3">
                                There are two simple approaches to
                                cryptocurrency investing. The first involves
                                purchasing cryptocurrencies via a digital wallet
                                at the going market rate, which is like to stock
                                investment. When you sell the currency for more
                                than you purchased it, you make money.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-2 col-md-12 col-12 col-sm-12">
                  <div className="asset-content1 mt-4 pt-3">
                    <div className="assets-content2">
                      <h4>Assets</h4>
                    </div>
                    <div
                      className="nav flex-column nav-pills mt-4"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <Link
                        to="/currency"
                        className="nav-link mt-2"
                        // id="v-pills-link1-tab"
                        // data-bs-toggle="pill"
                        // href="#v-pills-link1"
                        // role="tab"
                        aria-controls="v-pills-link1"
                        aria-selected="true"
                      >
                        Currency
                      </Link>
                      <Link
                        to="/commodities"
                        className="nav-link"
                        // id="v-pills-link2-tab"
                        // data-bs-toggle="pill"
                        // href="#v-pills-link2"
                        // role="tab"
                        aria-controls="v-pills-link2"
                        aria-selected="false"
                      >
                        Commodities
                      </Link>
                      <Link
                        to="/indices"
                        className="nav-link"
                        // id="v-pills-link3-tab"
                        // data-bs-toggle="pill"
                        // href="#v-pills-link3"
                        // role="tab"
                        aria-controls="v-pills-link3"
                        aria-selected="false"
                      >
                        Indices
                      </Link>
                     
                     
                    </div>
                  </div>
                  <div className="asset-content-box1 mt-4 pt-3">
                    <div className="assets-content3">
                      <h4>Analytics</h4>
                    </div>
                    <div
                      className="nav flex-column nav-pills mt-4"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <Link
                        to="/market"
                        className="nav-link active mt-2"
                        // id="v-pills-link6-tab"
                        // data-bs-toggle="pill"
                        // href="#v-pills-link6"
                        // role="tab"
                        aria-controls="v-pills-link6"
                        aria-selected="true"
                      >
                        Market Analytical
                      </Link>
                      <Link
                        to="/ecnomic"
                        className="nav-link"
                        // id="v-pills-link7-tab"
                        // data-bs-toggle="pill"
                        // href="#v-pills-link7"
                        // role="tab"
                        aria-controls="v-pills-link7"
                        aria-selected="false"
                      >
                        Economic Calendar
                      </Link>
                      <Link
                        to="/pipcalc"
                        className="nav-link"
                        // id="v-pills-link8-tab"
                        // data-bs-toggle="pill"
                        // href="#v-pills-link8"
                        // role="tab"
                        aria-controls="v-pills-link8"
                        aria-selected="false"
                      >
                        Trader Calculators
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="assets-icons-page">
                <div className="container">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-link1"
                      role="tabpanel"
                      aria-labelledby="v-pills-link1-tab"
                    >
                      <div className="row">
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div ref={currencychart2}></div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div ref={currencychart1}></div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div ref={currencychart3}></div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div ref={currencychart4}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-link2"
                      role="tabpanel"
                      aria-labelledby="v-pills-link2-tab"
                    >
                      <div className="row">
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div className="card-up aqua-gradient" />
                            <div className="avatar mx-auto white">
                              <img
                                src={require("../../assets/images/mani/tesla.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <h2>jcbkcnz</h2>
                            <div className="card-body text-center">
                              <h3>GBP/USD</h3>
                              <strong>
                                1.20712
                                <small className="text-success px-2">
                                  +0.87%
                                </small>
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div className="card-up aqua-gradient" />
                            <div className="avatar mx-auto white">
                              <img
                                src={require("../../assets/images/mani/icon2.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="card-body text-center">
                              <h3>EUR/USD</h3>
                              <strong>
                                1.06189
                                <small className="text-danger px-2">
                                  -0.69%
                                </small>
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div className="card-up aqua-gradient" />
                            <div className="avatar mx-auto white">
                              <img
                                src={require("../../assets/images/mani/icon4.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="card-body text-center">
                              <h3>USD/JPY</h3>
                              <strong>
                                130.385
                                <small className="text-danger px-2">
                                  -0.46%
                                </small>
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                          <div className="card testimonial-card mt-2 mb-3">
                            <div className="card-up aqua-gradient" />
                            <div className="avatar mx-auto white">
                              <img
                                src={require("../../assets/images/mani/icon5.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="card-body text-center">
                              <h3>USD/CHF</h3>
                              <strong>
                                0.92643
                                <small className="text-danger px-2">
                                  -1.02%
                                </small>
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade getmt5 mt-4 pb-5">
            <div className="getsmt5">
              <div className="container">
                <h2 className="text-center">Get Started</h2>
                <p class="getapara">
                  Get start trade anywhere, anytime. We have everything you
                  need: friendly customer support, the latest technology, and
                  multiple account types to find the  trades.
                </p>
                <div className="row mt-5 align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="row">
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images//algoimg/singupicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Signup</h4>
                          <p className="mb-0 font-13">
                            Enter your details and setup an account
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/verifyicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Verify</h4>
                          <p className="mb-0 font-13">
                            Upload your documents and get verified instantly
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/depositicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Deposit</h4>
                          <p className="mb-0 font-13">
                            Deposit an amount as low as $100
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/tradeicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Trade</h4>
                          <p className="mb-0 font-13">
                            Start trading any instrument of your choice and make
                            profits
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center getsbtn mt-5">
                        <div className="openlivebtn">
                          <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                            <button type="button" className="btn btn-primary">
                              Open Live Account
                            </button>
                          </a>
                        </div>
                        <div className="opendemobtn">
                          <a href="https://crm.milliva.com/opendemoaccount">
                            <button type="button" className="btn btn-primary">
                              Open Demo Account
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="getimg">
                      <img
                        src={require("../../assets/images/algoimg/getstartimg.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </div>
  );
}

export default Shares;
