import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/pipcalc.css";
import { useParams, useNavigate } from "react-router-dom";

const Pipcalc = () => {
    const forexcalcc = useParams();

    let path = window.location.pathname;

    

    const profitcalc = useRef();



    function RemoteCalc(WidgetConfig) {
        // if (WidgetConfig.Url == undefined) {
        //   WidgetConfig.Url = "https://www.cashbackforex.com";
        // }
        var ifrm = document.createElement("iframe");
        ifrm.setAttribute("calc-id", "ifrm-cbf-" + WidgetConfig.ContainerId); // assign an id
        ifrm.setAttribute("scrolling", "no");
        ifrm.setAttribute("widgetType", "calculator");
        ifrm.setAttribute(
            "style",
            "width:100%; border: 0;  height:100%; min-height:620px"
        );

        var frameSrc = WidgetConfig.Url;
        if (WidgetConfig.Lang != undefined) {
            if (
                WidgetConfig.Lang == "page" &&
                this.document.documentElement.lang != ""
            )
                frameSrc += "/" + this.document.documentElement.lang;
            else if (WidgetConfig.Lang != "page") frameSrc += "/" + WidgetConfig.Lang;
        }

        frameSrc += "/widgets/" + WidgetConfig.Calculator;

        if (WidgetConfig.IsDisplayTitle === true) {
            frameSrc += "?IsDisplayTitle=true";
        } else {
            frameSrc += "?IsDisplayTitle=false";
        }
        if (WidgetConfig.IsShowChartLinks !== undefined) {
            frameSrc += "&ShowChartLinks=" + WidgetConfig.IsShowChartLinks;
        }
        if (WidgetConfig.TopPaneStyle != undefined) {
            frameSrc += "&TopPaneStyle=" + WidgetConfig.TopPaneStyle;
        }
        if (WidgetConfig.BottomPaneStyle != undefined) {
            frameSrc += "&BottomPaneStyle=" + WidgetConfig.BottomPaneStyle;
        }
        if (WidgetConfig.ButtonStyle != undefined) {
            frameSrc += "&ButtonStyle=" + WidgetConfig.ButtonStyle;
        }
        if (WidgetConfig.TitleStyle != undefined) {
            frameSrc += "&TitleStyle=" + WidgetConfig.TitleStyle;
        }
        if (WidgetConfig.TextboxStyle != undefined) {
            frameSrc += "&TextboxStyle=" + WidgetConfig.TextboxStyle;
        }
        var width = "100%";
        if (WidgetConfig.ContainerWidth != undefined) {
            frameSrc += "&MaxWidth=" + WidgetConfig.ContainerWidth;
            if (
                WidgetConfig.ContainerWidth.endsWith("px") ||
                /^[0-9]*\.?[0-9]*$/.test(WidgetConfig.ContainerWidth)
            ) {
                const parsed = parseFloat(WidgetConfig.ContainerWidth, 10);
                if (isNaN(parsed)) {
                    width = WidgetConfig.ContainerWidth + "px";
                } else {
                    width = parsed + 1 + "px";
                }
            } else if (WidgetConfig.ContainerWidth.endsWith("%")) {
                width = WidgetConfig.ContainerWidth;
            }
        }
        if (WidgetConfig.ContainerId != undefined) {
            frameSrc += "&ContainerId=" + WidgetConfig.ContainerId;
        }
        if (
            WidgetConfig.AffiliateId != undefined &&
            WidgetConfig.AffiliateId != null
        ) {
            frameSrc += "&AffiliateId=" + WidgetConfig.AffiliateId;
        }
        if (WidgetConfig.CompactType) {
            frameSrc += "&CompactType=" + WidgetConfig.CompactType;
        }
        if (WidgetConfig.DefaultCurrencyFrom) {
            frameSrc += "&DefaultCurrencyFrom=" + WidgetConfig.DefaultCurrencyFrom;
        }
        if (WidgetConfig.DefaultCurrencyTo) {
            frameSrc += "&DefaultCurrencyTo=" + WidgetConfig.DefaultCurrencyTo;
        }
        if (WidgetConfig.HighlightColor) {
            frameSrc +=
                "&HighlightColor=" + encodeURIComponent(WidgetConfig.HighlightColor);
        }
        if (WidgetConfig.DefaultInstrument) {
            frameSrc +=
                "&DefaultInstrument=" +
                encodeURIComponent(WidgetConfig.DefaultInstrument);
        }
        if (WidgetConfig.DefaultExchange) {
            frameSrc +=
                "&DefaultExchange=" + encodeURIComponent(WidgetConfig.DefaultExchange);
        }

        if (WidgetConfig.DefaultBalance) {
            frameSrc += "&DefaultBalance=" + WidgetConfig.DefaultBalance;
        }

        if (WidgetConfig.DefaultPeriod) {
            frameSrc += "&DefaultPeriod=" + WidgetConfig.DefaultPeriod;
        }

        if (WidgetConfig.DefaultGain) {
            frameSrc += "&DefaultGain=" + WidgetConfig.DefaultGain;
        }

        if (WidgetConfig.IsShowEmbedButton !== undefined) {
            frameSrc += "&IsShowEmbedButton=" + WidgetConfig.IsShowEmbedButton;
        }
        if (WidgetConfig.IsShowBuyCryptoButton !== undefined) {
            frameSrc +=
                "&IsShowBuyCryptoButton=" + WidgetConfig.IsShowBuyCryptoButton;
        }
        ifrm.setAttribute("src", frameSrc);

        if (window.addEventListener) {
            window.addEventListener("message", onRemoteCalcMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent("onmessage", onRemoteCalcMessage, false);
        }

        var containerDiv = document.getElementById(WidgetConfig.ContainerId);
        containerDiv.style = "position: relative; max-width: " + width + ";";
        containerDiv.innerHTML = "";
        containerDiv.appendChild(ifrm);

        ifrm.addEventListener("load", function () {
            ifrm.contentWindow.postMessage(
                {
                    method: "calc-log",
                    url: window.location.href,
                    calc: WidgetConfig.Calculator,
                    host: window.location.host,
                },
                "*"
            );
        });
    }

    function onRemoteCalcMessage(event) {
        // Check sender origin to be trusted
        //if (RebateWidgetConfig.Url != "" && event.origin !== RebateWidgetConfig.Url) return;

        var data = event.data;

        if (typeof window[data.func] == "function") {
            window[data.func].call(null, data);
        }
    }

    window.RemoteCalc = RemoteCalc;



    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://www.cashbackforex.com/Content/remote/remote-widgets.js";
        script.async = false;
        script.innerHTML = JSON.stringify(
            RemoteCalc({
                Url: "https://www.cashbackforex.com",
                TopPaneStyle:
                    "YmFja2dyb3VuZDogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggYmxhY2s7IGJvcmRlci1ib3R0b206IG5vbmU7IA==",
                BottomPaneStyle:
                    "YmFja2dyb3VuZDogd2hpdGU7IGJvcmRlcjogc29saWQgMXB4IGJsYWNrOyBjb2xvcjogYmxhY2s7",
                ButtonStyle:
                    "YmFja2dyb3VuZDogb3JhbmdlOyBjb2xvcjogd2hpdGU7IGJvcmRlci1yYWRpdXM6IDIwcHg7",
                TitleStyle:
                    "dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==",
                TextboxStyle:
                    "YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==",
                ContainerWidth: "900",
                DefaultInstrument: "EUR-USD",
                HighlightColor: "#ffff00",
                IsDisplayTitle: true,
                IsShowChartLinks: false,
                IsShowEmbedButton: false,
                CompactType: "large",
                Calculator: "profit-calculator",
                ContainerId: "profit-calculator-252783",
            })
        );
        profitcalc.current.appendChild(script);
    }, []);



    useEffect(() => {

        setTimeout(() => {
            window.scroll({
                top: 0,


                behavior: 'smooth'
            });
        }, 100)
    }, [])
    return (
        <div>
            <div className="pipcalculator">

                <div
                    id="profit-calculator-252783"
                    ref={profitcalc}
                ></div>

            </div>
        </div>
    );
};

export default Pipcalc;

