import React,{useEffect} from "react";
import "../../assets/css/affilliate.css";
import { FaCheck } from "react-icons/fa";
const Affiliate = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <>
        <div className="affiliate-value">
          <section className="mt5trade">
            <div className="mt5cont">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 ">
                  <h1 className=" mb-2">Affiliate Program</h1>
                    <p className=" mb-3">
                      Milliva LTD affiliates earn the highest commissions for every referral they send so that you can monetize your client network to its full potential
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/mani/affiliate1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="affiliate-program-box mt-4">
            <div className="container">
              <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="affilaite-content1 text-center p-3">
                  <h2>Affiliates and Partners Program</h2>
                    <div className="row">
                      <div className="affi text-start col-lg-6 col-md-12 ">
                        <div className="affipage">
                        <p>
                            A strategic relationship with regional expertise is
                            a must for success in a competitive market. Regional
                            partners are critical in expanding investment
                            prospects to a scale. Our regional partners
                            are chosen not only for their distinctive trading,
                            financial, and management talents in their primary
                            business but also for their vision and determination
                            to be players.
                          </p>
                        </div>
                      </div>
                      <div className="affi text-start col-lg-6 col-md-12 ">
                        <div className="affipage">
                        <p>
                            Milliva LTD has a proven track record of forming
                            long-term alliances and collaborations with regional
                            and enterprises, exhibiting an instinctive
                            desire to adapt, innovate, and expand with shared
                            values and dedication while retaining reliability
                            and integrity.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="afliates text-center mt-5 ">
                  <h2 className="mb-3">
                      Who is Our Affiliate Program Designed For?
                    </h2>
                    <p className="">
                      Milliva LTD Affiliate Program is ideally suited to individuals
                      with a significant internet presence, such as Lead
                      Generators, Businesses, Social Media Influencers, Website
                      Owners – Finance and Trading Comparison Websites, SEO
                      Professionals, and Trading Educators.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="affiliate-box1 mt-4">
                    <div className="affiliate-content2 text-center mt-3">
                    <h2>Milliva LTD Affiliates and Partners Benefits</h2>
                    </div>
                    <div className="row mt-4 ">
                      <div className="col-lg-6 col-md-12 col-12 col-sm-12  ">
                        <div className="affiliate-content3 ">
                          <img
                            src={require("../../assets/images/mani/affi1.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 col-sm-12 ">
                        <div className="affiliate-content4 mt-3 ">
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            We offer one-on-one help and a full range of
                            services and knowledge to stimulate collective
                            progress.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            We provide a comprehensive range of trading tools
                            and resources to help you succeed in your trade.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            We believe in open trading and business environment.
                            You will get free access to cutting-edge
                            technologies and extensive resources.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            Our highly motivated and versatile crew provides
                            consistent round-the-clock service.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            We can provide all clients with customizable trading
                            conditions and consistency.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            Internally and publically, we uphold the highest
                            business integrity and ethical standards.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            We give prominence through employee training,
                            brand leverage, and collective thinking
                            methodologies.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            You will have a dedicated Partner's Personal Area
                            and Website, SEO and Internet Promotion, Marketing
                            Resources, and Meta Trader and Meta Manager
                            Accounts.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            We ensure integration with your region's most
                            reliable payment systems to make financial
                            transactions safe and straightforward for clients.
                          </p>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            If necessary, we assist in establishing a local
                            office, including mandatory staffing and steadfast
                            round-the-clock support.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="affliate-key mt-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="affliate-key1 text-center ">
                    <h2>Key Benefits Of Milliva LTD CPA Affiliate Program</h2>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="afiliate-key2 mt-3 py-3">
                  <p>Up to</p>
                    <h4>$800*</h4>
                    <span>CPA</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="afiliate-key2 mt-3 py-3">
                  <p>Access to the</p>
                    <h4>CellXpert</h4>
                    <span>Platform</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="afiliate-key2 mt-3 py-3">
                  <p>Offer Clients</p>
                    <h4>1,000+</h4>
                    <span>Tradable Instrument</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="afiliate-key2 mt-3 py-3">
                  <p>Fast and Easy</p>
                    <h4>CPA</h4>
                    <span>Withdrawals</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="afiliate-key2 mt-3 py-3">
                    <p>Access to Higher</p>
                    <h4>Conversion</h4>
                    <span>Landing Pages and Banners</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="afiliate-key2 mt-3 py-3">
                  <p>Multi-Lingual</p>
                    <h4>24/5</h4>
                    <span>Customer Support</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="affiliate-product1">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                 <div className="row align-items-center">
                <div className="col-lg-8 col-md-12 col-12">
                  <div className="affiliate-product2 mt-4 pt-3 ">
                  <h2>How to Become a CPA Affiliate</h2>
                  </div>
                  <div className="row">
                  <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="affiliate-product3 mt-3 py-3">
                    {/* <span>1</span> */}
                    <img
                  src={require("../../assets/images/introbroker/ibsignup.png")}
                  alt="logo"
                  className="img-fluid mb-3"
                />
                   <h4>Sign Up</h4>
                    <p>
                      Become a CPA Affiliate Partner with one of the largest
                      global multi-asset.
                    </p>
                  </div>
                </div>
                
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="affiliate-product3 mt-3 py-3">
                    {/* <span>2</span> */}
                    <img
                  src={require("../../assets/images/introbroker/referib.png")}
                  alt="logo"
                  className="img-fluid  mb-3"
                />
                    <h4>Refer Clients</h4>
                    <p>
                      Use our professionally designed marketing tools to appeal
                      to your audience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="affiliate-product3 mt-3 py-3">
                    {/* <span>3</span> */}
                    <img
                  src={require("../../assets/images/introbroker/earnmoreib.png")}
                  alt="logo"
                  className="img-fluid  mb-3"
                />
                    <h4>Earn More</h4>
                    <p>
                      Earn up to $800 CPA from each client you refer to our
                      milliva ltd site.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12  col-md-12 col-12">
                <div className="affi-product1 mt-3 ">
                  <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                    <button type="button" className="btn btn-warning m-auto d-block">
                    Sign Up Now
                    </button>
                  </a>
                </div>
              </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="become-image">
                  <img
                    src={require("../../assets/images/mani/af2.png")}
                     alt="logo" className="img-fluid" />
                  </div>
                </div>
              </div>
                </div>
              </div>     
            </div>
          </section>
        </div>
      </>
    </div>
  );
};

export default Affiliate;
