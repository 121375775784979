import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form, Button } from "react-bootstrap";
import "../../assets/css/legaldoc.css";
import { VALIDATION_PATTERN } from "../Signin/Store";
import { Subscribe } from "../Helper/Api";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const Legaldoc = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [show, setShow] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    reset();
  };

  const Submit = (data) => {
    let formdata = {
      ...data,
    };
    setLoading(true);

    Subscribe(formdata)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading(false);
          NotificationManager.success(res.data.message, "Success");
          window.open("https://www.milliva.com/uploads/legal-documents.pdf");
          handleClose();
        } else if (res.data.status == 0) {
          NotificationManager.error(res.data.message, "Failed");
          setLoading(false);
          handleClose();
        } else {
          NotificationManager.error(res.data.message, "Failed");
          setLoading(false);
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        handleClose();
        NotificationManager.error("Some Error Occured", "Failed");
      });
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 50,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <NotificationContainer />
      <div className="legalmodal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0">
            <Modal.Title>Subscription form</Modal.Title>
            <Button variant="" className="modalx" onClick={handleClose}>
              X{" "}
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  style={{ marginTop: "-14px" }}
                  autocomplete="off"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: VALIDATION_PATTERN.EMAIL,
                      message: "Enter Valid Email",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.email && <span role="alert">Email is required</span>}
                </p>
              </Form.Group>
            </Form>
            {loading ? (
              <>
                {" "}
                <button type="buttton" className="btn legalbtn m-auto d-block">
                  Loading...
                </button>
              </>
            ) : (
              <>
                {" "}
                <button
                  type="submit"
                  className="btn legalbtn m-auto d-block"
                  onClick={handleSubmit(Submit)}
                >
                  Subscribe Now
                </button>
              </>
            )}
          </Modal.Body>
        </Modal>
      </div>

      <div className="document">
        <section className="legaldocument">
          <div className="documentbanner">
            <div className="container">
              <div className="row align-items-center pt-5">
                <div className="col-md-12 col-lg-6">
                  <h1 className=" mt-5">Legal Documents</h1>
                  <p className=" mb-3">Trading drives Everything We Do.</p>
                </div>
                <div className="col-md-12 col-lg-6 ">
                  <img
                    src={require("../../assets/images/legal/document.png")}
                    alt="logo"
                    className="img-fluid mt-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="legal">
          <div className="container">
            <div className="row">
              <div className="legal-text mt-5 text-center">
                <p className="mb-0">  <strong>Milliva Ltd is proud to be regulated by the Comoros. Under License Number:L15696/MLTD</strong> </p>
                <p>
   
                  {/* <strong>
               
                    Milliva Limited is Registered By FSA Saint Vincent and the
                    Grenadines UNDER NO. 26829 BC 2022.
                    <p>
                      The Registrar of Companies for England and Wales hereby
                      certifies that MILLIVA LTD, on 4th August 2021, was
                      incorporated under the Companies Act 2006 as a private
                      company whose shares limit the company, and the situation
                      of its registered office is in England and Wales.
                    </p>
                    <br />
                    To provide the following Investment services and Ancillary
                    services
                  </strong>{" "} */}
                </p>
              </div>
              {/* <div className="services">
                <p className="inves parah">
                  <h5>Investment Services</h5>
                </p>
                <ul className="invesment">
                  <li>
                    Transmission and reception of orders concerning one or more
                    financial instruments.
                  </li>
                </ul>
              </div> */}
              {/* <div className="services">
                <p className="inves parah">
                  <h5>Ancillary Services</h5>
                </p>
                <ul className="invesment">
                  <li>
                    Financial analysis /investment research or other forms of
                    investment.
                  </li>
                  <li>
                    Administration and safekeeping of financial instruments,
                    including custodianship and related services.{" "}
                  </li>
                  <li>
                    Foreign exchange services are connected to the provision of
                    investment services.
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </section>
        {/* <section className="document invesmet mt-5">
          <div className="container">
            <div className="Capital market">
              <h2>Milliva LTD International Markets</h2>
              <p>
                MILLIVA LTD is registered in England and U.K Company House with
                company Incorporation number 13546193
              </p>
            </div>
            <div className="Capital limited">
              <h2>Milliva Markets Limited</h2>
              <p>
                Milliva Trade name of MILLIVA LTD UK, which acts as a Financial
                Registered company providing Trading Solutions for INTERNATIONAL
                LTD. MILLIVA LTD is Registered at FSA under Saint Vincent and
                the Grenadines, Registered number: 26892 BC 2022, and very soon,
                we will establish a Security license. 
              </p>
            </div>
            <div className="legal-icon mt-4">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-1"></div>
                  <div className="col-md-12 col-lg-2">
                    <div className="registerimage">
                      <img
                        src={require("../../assets/images/legal/rg12.png")}
                        alt="logo"
                        className="img-fluid mb-3"
                      />
                      <p>
                        Registered with FSA, Saint Vincent Grenadines, and
                        England and the U.K
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-2">
                    <div className="registerimage">
                      <img
                        src={require("../../assets/images/legal/rg22.png")}
                        alt="logo"
                        className="img-fluid  mb-3"
                      />
                      <p>Funds are deposited in AA Book Bank</p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-2">
                    <div className="registerimage">
                      <img
                        src={require("../../assets/images/legal/rg32.png")}
                        alt="logo"
                        className="img-fluid  mb-3"
                      />
                      <p>Audited Externally</p>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-2">
                    <div className="registerimage">
                      <img
                        src={require("../../assets/images/legal/rg42.png")}
                        alt="logo"
                        className="img-fluid  mb-3"
                      />
                      <p>Strictly Follows and Checks AML/MLTPA</p>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-2">
                    <div className="registerimage">
                      <img
                        src={require("../../assets/images/legal/rg52.png")}
                        alt="logo"
                        className="img-fluid  mb-3"
                      />
                      <p>Audits</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="AML/MLTPA mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="AML/MLTPA-text">
                <h2>AML/MLTPA</h2>
                <p>
                  Milliva, the Trading Website, Strictly obeys all the Money
                  Laundering and Terrorism (Prevention) Act, 2009(MLTPA)
                  regulations and policies. Our account opening process is
                  highly framed to stop any money laundering. So please go
                  through the AML policy, which directs the procedure to open an
                  account and the documents required by compliance with the
                  Money Laundering and Terrorism( Prevention) Act, 2009 (MLTPA).
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="regulation-content mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="mb-2">Legal Documents</h2>
                <p>Milliva Capital Ltd is proud to be regulated by the  Comoros. Our license number is L15696/MLTD, and our company is registered under number 15696. </p>
               <div>
                  <a href={require("../../assets/documents/Milliva Ltd License and IBC Documents.pdf")}
                  type="button" target="blank"
                  className="btn btn-primary legalbtn"
                 
                >
                   Click here to know more
                  </a>

                 
               </div>
              </div>
             
            </div>
          </div>
        </section>
        {/* <section className="documentation">
          <div className="international">
            <div className="container">
              <div className="documentation text text-center mt-5 pb-5">
                <h2>Legal Documentations</h2>
                <p>
                  The Financial Services Commission regulates Milliva LTD Capital
                  International Markets with an Investment Dealer Licence. The
                  Milliva LTD Capital Markets registered by FSA St. Vincent and the
                  Grenadines registered Milliva LTD Capital Markets Limited with
                  Number 26829 BC 2022, authorized to trade in commodity-based
                  derivatives instruments and securities.
                </p>
                <div className="member-text">
                  <p>
                    Milliva LTD is also a member of other competent
                    authorities/regulators and professionals.
                  </p>
                </div>
                <a
                  href="https://www.milliva.com/uploads/legal-documents.pdf"
                  download
                  target="_blank"
                  className="text-decoration-none"
                >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleShow}
                >
                  Click here to know more
                </button>
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default Legaldoc;
