import React from 'react'
import '../../assets/css/signin.css'

const Signin = () => {
  return (
    <div>
        <section className="sign-in-content">
  <div className="container py-5">
    <div className="row d-flex justify-content-center align-items-center">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5 mt-5 pt-4">
        <div className="card">
          <div className="text-center">
          <img src={require('../../assets/images/home/logo.png')} alt="logo" className='img-fluid mt-3' style={{width:"50",height:"10"}} />
          </div>
          <div className="card-body  text-center">
            <h2 className="mb-5">Sign in</h2>
            <div className="form-outline mb-4">
              <input
                type="email"
                id="typeEmailX-2"
                className="form-control form-control-lg"
                placeholder="Email"
              />
            </div>
            <div className="form-outline mb-4">
              <input
                type="password"
                id="typePasswordX-2"
                className="form-control form-control-lg"
                placeholder="Password"
              />
            </div>
            {/* Checkbox */}
            <div className="form-check d-flex justify-content-start mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="form1Example3"
              />
              <label className="form-check-label px-2" htmlFor="form1Example3">
                {" "}
                Remember Me{" "}
              </label>
              <a href="" className="ms-auto" style={{ color: "#478ffe" }}>
                Forget Password?
              </a>
            </div>
            <button className="btn btn-primary btn-lg btn-block" type="submit">
              Sign In
            </button>
            <div className="text-center mt-2">
              <span>Or</span>
            </div>
          </div>
          <div className="sign-in-icons d-flex py-3">
            <div className="sign-in-google">
              <h6 className="px-3">
              <img src={require('../../assets/images/mani/google.png')} alt="logo" className='img-fluid'  />
                <span>Google</span>
              </h6>
            </div>
            <div className="sign-in-facebook">
              <h6 className="">
              <img src={require('../../assets/images/mani/facebook.png')} alt="logo" className='img-fluid ' />
                <span>Facebook</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Signin