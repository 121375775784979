import React, { useEffect } from "react";
import "../../assets/css/accountype.css";
import Carousels from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate, Link } from "react-router-dom";

const responsive4 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const Accountype = () => {
  const navigate = useNavigate();

  const Change = () => {
    navigate("/liveacc");
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <div className="accounttype">
        <section className="mt5trade">
          <div className="mt5cont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 ">
                  <h1 className=" mb-3">Smart Accounts For Smart People</h1>
                  <p className=" mb-3">
                    Every type of account offered by Milliva LTD is a trading
                    tool that enables you to work in international financial
                    markets.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/accountype/accountbanner1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="accoun1 mt-5">
          <div className="container">
            <div className="acctype">
              <h2 className="text-center mb-3">
                Account Types in Forex Trading
              </h2>
              <p className="text-center">
                A minimum deposit of 100$ and a zero fee policy for traders to
                open different forex accounts, including standard, ECN, ELITE,
                and PREMIUM accounts. Our traders' interests are always our
                priority at Milliva LTD. To provide you with a good trading
                environment, we won't charge your bank account/credit card fees.
              </p>
            </div>
          </div>
        </section>
        <section className="acctble mt-5">
          <div className="container">
            <div className="acctpri">
              <div className="row mt-5">
                <div className="col-md-12 col-lg-2">
                  <div className="accountypes">
                    <ul>
                      <li>Trading Platform</li>
                      <li>Spread</li>
                      <li>Minimum deposit</li>
                      <li>Trading Lot size</li>
                      <li>Maximum leverage</li>
                      <li>Execution</li>
                      <li>Swap Charges</li>
                      <li>Margin call</li>
                      <li>Stop out level</li>
                      <li>Personal account Manager</li>
                      <li>Account currency</li>
                      <li>Commission for forex pairs</li>
                      <li>Open Account</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 col-lg-10">
                  <div className="pricinglist">
                    <div className="row">
                      <div className="col-md-12 col-lg-4">
                        <div class="pricing-table gprice-single pb-4">
                          <div class="head">
                            <h5 class="title mt-3">STANDARD</h5>
                          </div>
                          <div class="content">
                            <div class="price">
                              <h6>$100</h6>
                            </div>
                            <ul>
                              <li>MT5</li>
                              <li>From 1.3 pips</li>
                              <li>$100</li>
                              <li>0.01-100Lots</li>
                              <li> Upto 1:400 </li>
                              <li> STP/ECN</li>
                              <li> YES</li>

                              <li> 50%</li>
                              <li> 30%</li>
                              <li> NO</li>
                              <li> USD</li>
                              <li>NO</li>
                            </ul>
                            <div class="sign-up">
                              <a
                                href="https://crm.milliva.com/client/register/6169896c79fd7"
                                class="btn bordered radius"
                              // onClick={Change}
                              >
                                OPEN LIVE ACCOUNT
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div class="pricing-table gprice-single pb-4">
                          <div class="head">
                            <h5 class="title mt-3">PREMIUM</h5>
                          </div>
                          <div class="content">
                            <div class="price">
                              <h6>$1000</h6>
                            </div>
                            <ul>
                              <li>MT5</li>
                              <li>From 0.5 pips </li>
                              <li>$1000</li>
                              <li>0.01-100Lots</li>
                              <li> Upto 1:200 </li>
                              <li> STP/ECN</li>
                              <li> YES</li>

                              <li> 50%</li>
                              <li> 30%</li>
                              <li> Yes</li>
                              <li> USD</li>

                              <li>$5</li>
                            </ul>
                            <div class="sign-up">
                              <a
                                href="https://crm.milliva.com/client/register/6169896c79fd7"
                                class="btn bordered radius"
                              >
                                OPEN LIVE ACCOUNT
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div class="pricing-table gprice-single pb-4">
                          <div class="head">
                            <h5 class="title mt-3">ELITE</h5>
                          </div>
                          <div class="content">
                            <div class="price">
                              <h6>$500</h6>
                            </div>
                            <ul>
                              <li>MT5</li>
                              <li>From 1.1 pips </li>
                              <li>$500</li>
                              <li>0.01-100Lots</li>
                              <li> Upto 1:300 </li>
                              <li> STP/ECN</li>
                              <li>YES</li>

                              <li> 50%</li>
                              <li> 30%</li>
                              <li> NO</li>
                              <li> USD</li>

                              <li> NO</li>
                            </ul>
                            <div class="sign-up">
                              <a
                                href="https://crm.milliva.com/client/register/6169896c79fd7"
                                class="btn bordered radius"
                              >
                                OPEN LIVE ACCOUNT
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 col-lg-3">
                        <div class="pricing-table gprice-single pb-4">
                          <div class="head">
                            <h5 class="title mt-3">PREMIUM</h5>
                          </div>
                          <div class="content">
                            <div class="price">
                              <h6>$10000</h6>
                            </div>
                            <ul>
                              <li>MT5</li>
                              <li>From 0 pips </li>
                              <li>$10000</li>
                              <li>0.01-100Lots</li>
                              <li> Upto 1:500</li>

                              <li> STP/ECN</li>
                              <li> NO</li>

                              <li> 50%</li>
                              <li> 30%</li>
                              <li>Yes</li>
                              <li> USD</li>
                              <li> $5</li>
                            </ul>
                            <div class="sign-up">
                              <a
                                href="https://crm.milliva.com/client/register/6169896c79fd7"
                                class="btn bordered radius"
                              >
                                OPEN LIVE ACCOUNT
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="acctype3 mt-5 ">
          <div className="container">
            <h2 className="text-center mb-3 pt-4">
              {" "}
              Choose the optimal account for your trading strategy
            </h2>
            <p className="text-center">
              Each Milliva LTD account type is a universal trading tool that aids in
              working on international financial markets. When working with
              Milliva LTD, you will have access to any trading symbol available,
              regardless of the account type you choose.
            </p>
            <div className="stand">
              <h5 className="mb-3">Standard</h5>
              <p>
                If you are new to trading, you can start trading with one of our
                standard accounts. Almost all of our popular accounts offer
                market execution, low spread, with no fees.
              </p>
            </div>
            <div className="ecn mt-3">
              <h5 className="mb-3">Ecn</h5>
              <p>
                ECN (Electronic Communication Network) accounts are preferred by
                professional traders seeking the finest trading circumstances.
                The significant advantages of an ECN account include quick
                market execution, low spreads (from 0.0 pip),  quotes with
                no wait, and a wide variety of liquidity providers.
              </p>
            </div>
            <div className="premi mt-3">
              <h5 className="mb-3">premium</h5>
              <p>
                Trade like a pro with our premium account, the main benefits of
                an ECN account include swift market execution, low spreads (from
                0.0 pip), and a wide range of liquidity providers.
              </p>
            </div>
          </div>
        </div>
        <section className="fastsecure mt-5">
          <div className="fastsectrade">
            <div className="container">
              <h2 className="text-center mb-3">Fast and Secure Trading</h2>
              <p>
                With easy to make a deposit into your trading account you will
                be in the markets in minutes and when you choose a law or no fee
                option you will have more left to trade with.
              </p>
              <div className="accslider">
             
              </div>
              <div className="accsli1">
                <img
                  src={require("../../assets/images/home/tether-ico.png")}
                  alt="logo"
                  className="img-fluid m-auto d-block"
                />
              </div>
            </div>
          </div>
        </section>
        <div className="meta5 mt-3">
          <div className="container">
            <h2 className="text-center">Meta Trader5</h2>

            <div className="row mt-4">
              <div className="col-md-12 col-lg-4">
                <div className="appdownloadicons d-flex align-items-center">
                  <div className="appicon1">
                    {" "}
                    <img
                      src={require("../../assets/images/accountype/android.png")}
                      alt="logo"
                      className="img-fluid mx-3"
                    />
                  </div>
                  <div className="appicon2">
                    {" "}
                    <a
                      href="https://download.mql5.com/cdn/mobile/mt5/android?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes"
                      className="dropdown-item"
                    // onClick={handleCaptureClick}
                    >
                      <p>Download For</p> <h6>Android</h6>{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="appdownloadicons d-flex align-items-center">
                  <div className="appicon1">
                    {" "}
                    <img
                      src={require("../../assets/images/accountype/mac.png")}
                      alt="logo"
                      className="img-fluid mx-3 ios-mobi"
                    />
                  </div>
                  <div className="appicon2">
                    <a
                      className="dropdown-item"
                      href="https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes
                            "
                    >
                      <p>Available on the</p>
                      <h6>IOS</h6>
                    </a>
                  </div>
                  <div className="ios-mac-line">/</div>
                  <div className="appicon2">
                    <a
                      className="dropdown-item"
                      href="https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes
                            "
                    >
                      <h6 className="ios-home">MAC</h6>
                    </a>
                  </div>
                  <div className="appicon1">
                    {" "}
                    <img
                      src={require("../../assets/images/accountype/ios.png")}
                      alt="logo"
                      className="img-fluid mx-3 mac-lap "
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12 col-lg-3">
                <div className="appdownloadicons d-flex align-items-center">
                  <div className="appicon1">
                    <img
                      src={require("../../assets/images/accountype/mac.png")}
                      alt="logo"
                      className="img-fluid mx-3"
                    />
                  </div>
                  <div className="appicon2">
                    {" "}
                    <p>Download For</p>
                    <h6>Mac</h6>
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 col-lg-4">
                <div className="appdownloadicons d-flex align-items-center">
                  <div className="appicon1">
                    <img
                      src={require("../../assets/images/accountype/windows.png")}
                      alt="logo"
                      className="img-fluid mx-3 "
                    />
                  </div>
                  <div className="appicon2">
                    <a
                      className="dropdown-item"
                      href="https://download.mql5.com/cdn/web/19448/mt5/milliva5setup.exe"
                      download
                    >
                      {" "}
                      <p>Download For</p>
                      <h6>Windows</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="getmt5 mt-5 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center mb-3">Get Started</h2>
              <p class="getapara">
                Get start trade anywhere, anytime. We have everything you need:
                friendly customer support, the latest technology, and multiple
                account types to find the trades.
              </p>
              <div className="row mt-5 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/signupicon2.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Signup</h5>
                        <p className="mb-0 font-13">
                          Enter your details and setup an account
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/verifyicon2.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Verify</h5>
                        <p className="mb-0 font-13">
                          Upload your documents and get verified instantly
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/depositicon1.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Deposit</h5>
                        <p className="mb-0 font-13">
                          Deposit an amount as low as $100
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/tradeicon1.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Trade</h5>
                        <p className="mb-0 font-13">
                          Start trading any instrument of your choice and make
                          profits
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Live Account
                          </button>
                        </a>
                      </div>
                      <div className="opendemobtn">
                        <a href="https://crm.milliva.com/opendemoaccount">
                          <button type="button" className="btn btn-primary">
                            Open Demo Account
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                    <img
                      src={require("../../assets/images/getstartimg.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Accountype;
