import React,{useEffect} from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../../assets/css/algo.css";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
const Algo = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <section className="Algo-trade">
        <section id="Algo">
          <section className="algo-banner">
            <div className="algo-trade-banner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                    <h1 className="mb-3">Algo Trading</h1>
                    <p>
                      Stay Ahead with Algo Trading
                    </p>
					 <p>
                      Quality trade executions are Vital to your Success
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/algoimg/algo-banner.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* ==================================banner-next=========================== */}
        <div className="algotrading mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h2 className="mb-3">Algo Trading</h2>
                <h5 className="mb-3">How does Algorithmic Trading Work?</h5>
                <p>
                  <i className="altick">
                    <FaCheck />
                  </i>{" "}
                  Algorithmic trading is otherwise known as Algo trading, in
                  this kind of trade, there will not be much human interference,
                  while AI programs are employed to trade at higher speed and
                  volumes.
                </p>
                <p>
                  {" "}
                  <i className="altick">
                    <FaCheck />
                  </i>{" "}
                  By using algorithmic trade, you can make orders within a
                  fraction of a second. The trading Website Milliva LTD offers
                  completely automated trading bots that will place all trade
                  entries without any manual intervention in your trading
                  account based on proven strategies.{" "}
                </p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="algorithem">
                  <img
                    src={require("../../assets/images/algoimg/algo-basics.png")}
                    alt="algo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===========================why use============================== */}
        <div className="why-use">
          <div className="container">
            <h5 className="mb-3">Why Use Algorithmic Trading?</h5>
            <p>
              <i className="altick">
                <FaCheck />
              </i>{" "}
              With algorithmic trading, you can use various indicators and can
              make orders that humans can’t do.
            </p>
            <p>
              <i className="altick">
                <FaCheck />
              </i>{" "}
              Even with rapid analysis and execution, you will get numerous
              opportunities to trade. Also, you can convert your trading
              ideologies into 100% Algo trading systems along with customization
              and flexibility.
            </p>
            <p>
              <i className="altick">
                <FaCheck />
              </i>{" "}
              The trading platform Milliva LTD is home for algo traders,
              experiencing the next-gen algorithmic trading systems &amp;
              advanced techniques.
            </p>
          </div>
        </div>
        {/* =======================how to do======================== */}
        <div className="How-to-do">
          <div className="container">
            <h5 className="mb-3">How To Do Algorithmic Trading?</h5>
            <p>
              <i className="altick">
                <FaCheck />
              </i>{" "}
              Algo trading is one of the trading methods that involve using
              coded programs to find and implement a large number of trades in
              the market.
            </p>
            <p>
              <i className="altick">
                <FaCheck />
              </i>{" "}
              It functions automatically according to the code that has been
              developed.
            </p>
            <p>
              {" "}
              <i className="altick">
                <FaCheck />
              </i>{" "}
              While Milliva LTD, the share broker builds your own algorithms or
              uses off-the-self solutions, to trade CFDs.
            </p>
            <p>
              {" "}
              <i className="altick">
                <FaCheck />
              </i>{" "}
              We employ cutting-edge trading strategies to monitor the order
              book and manage your execution.
            </p>
          </div>
        </div>
        {/* ===========================benifits-=========================== */}
        <div className="benifits mt-5">
          <div className="container">
            <h2 className="">Benefits Of Algorithmic Trading</h2>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={require("../../assets/images/algoimg/algo-benifits.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h5 className="mb-3">
                  Algo trading has the following advantages:
                </h5>
                <p>
                  <i className="altick">
                    <FaCheck />
                  </i>{" "}
                  Trades happen at highly competitive prices
                </p>
                <p>
                  <i className="altick">
                    <FaCheck />
                  </i>
                  Your order placement is fast and precise
                </p>
                <p>
                  <i className="altick">
                    <FaCheck />
                  </i>{" "}
                  Trades are timed properly and instantly to stand away from
                  price changes
                </p>
                <p>
                  {" "}
                  <i className="altick">
                    <FaCheck />
                  </i>
                  Low transaction costs,Automated checks on various market
                  conditions at the same time
                </p>
                <p>
                  <i className="altick">
                    <FaCheck />
                  </i>{" "}
                  There will be no errors while placing trades, The possibility
                  of traders making issues based on psychological factors and
                  emotions can be avoided
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* =======================================how-it-works======================= */}
        <section className="getmt5 mt-0 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center mb-3">Join our Algo program</h2>
              <p className="getapara algo-join">The trading platform Milliva helps traders to enjoy next-generation algo trading systems and make a profit.</p>
              <div className="row mt-5 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-4">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images//algoimg/singupicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Signup</h5>
                        <p className="mb-0 font-13">
                          Enter your details and setup an account
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/algoimg/verifyicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Verify</h5>
                        <p className="mb-0 font-13">
                          Upload your documents and get verified instantly
                        </p>
                      </div>
                    </div> */}
                    <div className="col-md-12 col-lg-4">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/algoimg/depositicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Deposit</h5>
                        <p className="mb-0 font-13">
                        Deposit Minimum of $100
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/algoimg/tradeicon1.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Algo Trade</h5>
                        <p className="mb-0 font-13">
                          Start trading any instrument of your choice and make
                          profits
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Live Account
                          </button>
                        </a>
                      </div>
                      <div className="opendemobtn">
                        <a href="https://crm.milliva.com/opendemoaccount">
                          <button type="button" className="btn btn-primary">
                            Open Demo Account
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                    <img
                      src={require("../../assets/images/algoimg/getstartimg.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Algo;
