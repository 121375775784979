import React,{useEffect} from "react";
import "../../assets/css/segregate.css";
import { FaCheck } from "react-icons/fa";
const Segregate = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
          top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <>
        <div className="segregate-value">
          <section className="mt5trade">
            <div className="mt5cont">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6">
                  <h1 className=" mb-2">Segregate Account</h1>
                    <p className=" mb-3">
                      Milliva Ltd offers its clients a unique service - opening segregated accounts protects your capital from the risks of forceful circumstances
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6 ">
                    <img
                      src={require("../../assets/images/mani/segregateaccount.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
     
          <div className="segregate-divider">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="segregate-content2">
                    <h2 className="px-3 py-3" style={{ color: "#f18c20" }}>
                      Example:
                    </h2>
                    <p>
                      <i className="altick">
                        <FaCheck />
                      </i>
                      The client requests that Milliva LTD Trading Platform open a
                      segregated account for him with a balance of $100,000.
                      When the account is opened, the client requests segregated
                      account service and delivered the necessary paperwork. The
                      customer must meet two requirements after the business
                      confirms the service to receive $100,000 to the account
                      70% of the transfer, or $70,000, will be replenished.
                    </p>
                    <p>
                      <i className="altick">
                        <FaCheck />
                      </i>
                      Should send bank statements attesting to the $100,000 in
                      their bank account.
                    </p>
                    <p>
                      <i className="altick">
                        <FaCheck />
                      </i>
                      The client obtains a trading account with $100,000 on it
                      as soon as all the requirements are met and 30% of this
                      amount is kept in a personal bank account, protecting it
                      from any dangers associated with the company's operations
                      due to force majeure.
                    </p>
                    <p>
                      <i className="altick">
                        <FaCheck />
                      </i>
                      The segregated account mechanism used by the Milliva LTD Forex
                      Trading platform offers its clients complete protection.
                      It ensures their money is as safe as it would be in a bank
                      account.
                    </p>
                    <p>
                      <i className="altick">
                        <FaCheck />
                      </i>
                      The minimum sum of deposits for a segregated account is
                      $50,000.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Segregate;
