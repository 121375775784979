import axios from "axios";
import commonHelper from "./Common";

export const helper = {
  baseURL: function () {
    // const url = " http://192.168.29.37";
    const url = "https://backend.milliva.com";
    return url;
  },
  post: async function (url, data) {
    let params = {};
    if (data.payload) {
      params["payload"] = commonHelper?.objectEncrypt(data?.payload);
    } else {
      params["payload"] = commonHelper?.objectEncrypt(data);
    }
    let axiosconfig;

    if (localStorage.getItem("Token")) {
      axiosconfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "true",
        },
      };
    } else {
      axiosconfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "true",
        },
      };
    }
    return await axios
      .post(url, params, axiosconfig)
      .then((response) => {

        const output = response;

        return output;
      })
      .catch((err) => {});
  },
};

export const Subscribe = async (ciphertext) => {
  return axios.post(helper.baseURL() + `/api/submit-subsctiption`, ciphertext);
};

export const Cotactus = async (ciphertext) => {
  return axios.post(helper.baseURL() + `/api/submit-contact-us`, ciphertext);
};

export const Requestcall = async (ciphertext) => {
  return axios.post(
    helper.baseURL() + `/api/submit-request-calback`,
    ciphertext
  );
};

export const promotion = async (ciphertext) => {
  return axios.post(
    helper.baseURL() + `/api/submit-promotion-form`,
    ciphertext
  );
};
