import React, { useEffect } from "react";
import "../../assets/css/whychoose.css";
const Whychoose = () => {
  useEffect(() => {

    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: 'smooth'
      });
    }, 100)
  }, [])
  return (
    <div>
      <div className="why choose us">
        <section className="choose us">
          <div className="choosebanner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h1 className=" mb-2">Reasons to Count On Us</h1>
                  <p className=" mb-3">Your Financial Advisor For Success Stories</p>

                </div>
                {/* <div className="col-md-12 col-lg-6 why-banner-img">
                  <img
                    src={require("../../assets/images/whychoose/why-choose-us.png")}
                    alt="logo"
                    className="img-fluid mt-4"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="choose mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="choose-text text-center">
                <h2>Why Choose Us</h2>
                <p>
                  <strong>User-Friendly Interface:</strong>  Our platform is designed to cater to both beginners and seasoned traders. Intuitive navigation and easy-to-use tools ensure a smooth trading experience.
                  <br></br>
                  <strong>Low Fees & Competitive Spreads:</strong> Maximize your profits with minimal fees and some of the most competitive spreads in the market.<br></br>
                  <strong>Secure & Reliable:</strong> We prioritize the security of your funds and data with advanced encryption technology and secure servers, ensuring a safe trading environment.<br></br>
                  <strong>24/7 Support:</strong> Our dedicated support team is available round-the-clock to assist you with any queries or issues, providing personalized assistance whenever you need it.<br></br>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Benefits">
          <div className="container">
            <div className="Benefits text-center mt-4">
              <div className="fadeInUp" style={{ animationDelay: "2s" }}>
                <h2>Benefits</h2>
              </div>
            </div>
            <div className="pamm mt-4">
              <div className="container">
                <div className="fadeInUp" style={{ animationDelay: "2s" }}>
                  <div className="row">
                    <div className="col-lg-4 ">
                      <div className="pamm d-flex">
                        <div className="pamm-image ">
                          <img
                            src={require("../../assets/images/whychoose/levarage2.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="Fidelis-text mt-3">
                          <h5>Leverage</h5>
                        </div>
                      </div>
                      <p>
                        Milliva LTD provides high-leverage trading and helps
                        traders earn profit. Depending on the market
                        strategy, you can choose the most accessible and
                        comfortable leverage. So make sure you use this
                        trading mechanism to see soaring profits.{" "}
                      </p>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="pamm d-flex">
                        <div className="market-image ">
                          <img
                            src={require("../../assets/images/whychoose/market-analysis2.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="market-text mt-3">
                          <h5>Market Analysis</h5>
                        </div>
                      </div>
                      <p>
                        With proper market analysis, trading can be profitable.
                        Our market analysis section contains predicts and
                        reviews: technical, expert outlooks, and fundamental
                        analysis. The data compiled by Milliva LTD will keep you
                        updated. Always travel with the market trends.
                      </p>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="pamm d-flex">
                        <div className="tradecopy -image ">
                          <img
                            src={require("../../assets/images/whychoose/Deposit-and-Withdrawal2.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="tradecopy -text mt-3">
                          <h5>Deposit and Withdrawal</h5>
                        </div>
                      </div>
                      <p>
                        Milliva LTD ensures right conditions for deposits and
                        withdrawals and lets you enjoy a broad array of
                        options for deposits and withdrawals. We guarantee
                        transparency regardless of any payment way you
                        choose.{" "}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="support mt-3">
          <div className="container">
            <div className="row">
              <div className="multilanguage text-center mt-4">
                <h2>Why trade with us</h2>
              </div>
              <div className="customer mt-3">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 ">
                      <div className="hovercard">
                        <div className="fidlies d-flex">
                          <div className="Fidelis-image ">
                            <img
                              src={require("../../assets/images/whychoose/fidlies.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="Fidelis-text mt-3 mx-3">
                            <h5>Why go with Milliva LTD?</h5>
                          </div>
                        </div>
                        <p>
                          The choice is obvious. Milliva LTD focuses on “Benefit for Traders” and offers the lowest spreads, instant execution; no requotes, and no dealing desk.

                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="hovercard">
                        <div className="fidlies d-flex">
                          <div className="Fidelis-image ">
                            <img
                              src={require("../../assets/images/whychoose/lowest-spreds.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="Fidelis-text mt-3 mx-3">
                            <h5>Lowest Spreads</h5>
                          </div>
                        </div>
                        <p>
                          Milliva LTD offers the tightest spreads in the forex
                          Industry. With our ECN trading, enjoy 0.0 spreads on
                          EURUSD and EURGBP.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="hovercard">
                        <div className="fidlies d-flex">
                          <div className="Fidelis-image ">
                            <img
                              src={require("../../assets/images/whychoose/lighting.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="Fidelis-text mt-1 mx-3">
                            <h5>Lightning Fast Execution</h5>
                          </div>
                        </div>
                        <p>
                          Our lightning-speed executions help you to have
                          successful trades every time.Technology
                          Milliva LTD is determined to deliver secure, advanced, and
                          reliable technologies at your fingertips.
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-4 ">
                        <div className="hovercard">
                          <div className="fidlies d-flex">
                            <div className="Fidelis-image ">
                              <img
                                src={require("../../assets/images/whychoose/supprior.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Fidelis-text mt-1 mx-3">
                              <h5>Technology</h5>
                            </div>
                          </div>
                          <p>
                            Milliva LTD is committed to deliver advanced, dependable
                            and secure technologies at your fingertips.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="hovercard">
                          <div className="fidlies d-flex">
                            <div className="Fidelis-image ">
                              <img
                                src={require("../../assets/images/whychoose/signal.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Fidelis-text mt-1 mx-3">
                              <h5>Free Real Time Signals</h5>
                            </div>
                          </div>
                          <p>
                            Our real-time signals improve the quality and
                            quantity of your currency trading opportunities and
                            keep you current.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="hovercard">
                          <div className="fidlies d-flex">
                            <div className="Fidelis-image ">
                              <img
                                src={require("../../assets/images/whychoose/un-parrelal2.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Fidelis-text mt-1 mx-3">
                              <h5>Un-parallel Customer Service</h5>
                            </div>
                          </div>
                          <p>
                            We are always around the clock to answer your
                            queries related to currency trading.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="advantage mt-4">
          <div className="why-choose-first">
            <h2>Milliva LTD Advantage</h2>
            <p>
              We are proud of our reputation for reliability,
              dependability, customer service, and customer satisfaction. We
              constantly benchmark ourselves against the competition to ensure
              you get the  value for your business.
            </p>
          </div>

        </div>

      </div>
    </div>

  );
};

export default Whychoose;
