import React, { useEffect } from "react";
import "../../assets/css/introbroker.css";
const Introbroker = () => {
  useEffect(() => {

    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: 'smooth'
      });
    }, 100)
  }, [])
  return (
    <div>
      <div className="introbroker">
        <section className="introbro">
          <div className="intorbrobanner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h1 className="mb-3">Introducing Broker</h1>
                  <p className="text-left  mb-3">
                    Build your network with  Milliva LTD and give your clients the  technical services, liquidity, and support
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/introbroker/introbroker6.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* bannersecend */}
        {/* mainsec */}
        <section className="inbro mt-5">
          <div className="container">
            <div className="inbrosub">
              <h2 className="text-center mb-3">
                Introducing broker
              </h2>
              <p className="text-center">
                Milliva LTD offers Introducing Broker which has a direct working
                relationship with clients, a middleman between
                two parties; the customer and the main broker.
              </p>
            </div>
          </div>
        </section>
        <section className="inbroicons">
          <div className="container">
            <div className="inbrosubicons pt-4">
              <div className="row justify-content-center mt-3">
                <div className="col-md-12 col-lg-3">
                  <div className="iconintro1 d-flex align-items-center">
                    <div className="imginb">
                      <img
                        src={require("../../assets/images/introbroker/tradecommision1.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="parainb">
                      <p>
                        Smooth daily <br />
                        commission payouts
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="iconintro1 d-flex align-items-center">
                    <div className="imginb">
                      <img
                        src={require("../../assets/images/introbroker/tradesearch.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="parainb">
                      <p>
                        Favourable trading
                        <br /> conditions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="iconintro1 d-flex align-items-center">
                    <div className="imginb">
                      <img
                        src={require("../../assets/images/introbroker/uncappedcomm.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="parainb">
                      <p>
                        Uncapped
                        <br /> commission
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="iconintro2 d-flex align-items-center">
                    <div className="imginb">
                      <img
                        src={require("../../assets/images/introbroker/transparenttrade.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="parainb">
                      <p>
                        Transparent program with <br />
                        no hidden restrictions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="insubbrwk">
          <div className="introbrowk mt-5">
            <div className="container">
              <div className="intsubwk">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/introbroker/introbroker.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <h2 className="mb-3">
                      How does Introducing Broker (IB) Program work?
                    </h2>
                    <p>
                      The Milliva LTD Introducing Broker (IB) program is carefully
                      designed to help you earn a volume-based rebate on every
                      trade done by the clients that you bring to Milliva LTD, with
                      flexible incentive structures adapted to your needs.
                    </p>
                    <p>
                      We reward IBs with some of the rebates in the market
                      because we value your business.
                      clear IB reporting, and exceptional IB infrastructure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="brodesign mt-0">
          <div className="container">
            <div className="subbrokerdesign">
              <h2 className="mb-3 text-center">
                Who is the Introducing Broker Program designed for?
              </h2>
              <p className="text-center">
                Milliva's LTD Introducing Broker (IB) program is suited for
                those with a large trader audience and
                Referrers.
              </p>

              <div className="parentsec">
                <h4 className="text-center">Some of our partners include:</h4>
                <div className="parentsecli row mt-3 d-flex justify-content-center">
                  {/* <div className="col-md-12 col-lg-4">
                    <div className="d-flex partnerslist align-items-center">
                      <div className="partnericon1">
                        <img
                          src={require("../../assets/images/introbroker/tradeeducator.png")}
                          alt="logo"
                          className="img-fluid mx-2"
                        />
                      </div>
                      <div className="parpara">
                        <p>Educators</p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-md-12 col-lg-4">
                    <div className="d-flex partnerslist align-items-center">
                      <div className="partnericon1">
                        <img
                          src={require("../../assets/images/introbroker/tradesignal.png")}
                          alt="logo"
                          className="img-fluid  mx-2"
                        />
                      </div>
                      <div className="parpara">
                        <p>Signal Service Providers</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 col-lg-4">
                    <div className="d-flex partnerslist align-items-center">
                      <div className="partnericon1">
                        <img
                          src={require("../../assets/images/introbroker//fitechicon1.png")}
                          alt="logo"
                          className="img-fluid  mx-2"
                        />
                      </div>
                      <div className="parpara">
                        <p>FinTech Firms</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div className="d-flex partnerslist align-items-center">
                      <div className="partnericon1">
                        <img
                          src={require("../../assets/images/introbroker/tradereferrs.png")}
                          alt="logo"
                          className="img-fluid  mx-2"
                        />
                      </div>
                      <div className="parpara">
                        <p> Referrers</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" parentsecli row mt-3">
                  {/* <div className="col-md-12 col-lg-4">
                    <div className="d-flex partnerslist align-items-center">
                      <div className="partnericon1">
                        <img
                          src={require("../../assets/images/introbroker/tradereabeta.png")}
                          alt="logo"
                          className="img-fluid  mx-2"
                        />
                      </div>
                      <div className="parpara">
                        <p> Rebate Providers</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div className="d-flex partnerslist align-items-center">
                      <div className="partnericon1">
                        <img
                          src={require("../../assets/images/introbroker/tradeea.png")}
                          alt="logo"
                          className="img-fluid  mx-2"
                        />
                      </div>
                      <div className="parpara">
                        <p> EA Providers</p>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="insbrkey mt-5">
          <div className="container-fluid">
            <div className="keybene">
              <h2 className="text-center">
                Key Benefits of the Milliva LTD Introducing Broker Program
              </h2>
              <div className="row align-items-center justify-content-center mt-4">
                <div className="col-md-12 col-lg-4">
                  <div className="seckey1">
                    <span>Up to</span>
                    <span>
                      {" "}
                      <strong>$25</strong>
                    </span>
                    <span>Rebates</span>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="seckey1">
                    <span>Multi-lingual</span>
                    <span>
                      {" "}
                      <strong>Promotional</strong>
                    </span>
                    <span>Material</span>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="seckey1">
                    <span>Dedicated</span>
                    <span>
                      {" "}
                      <strong>Account</strong>
                    </span>
                    <span>Managers</span>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-center mt-5">
                <div className="col-md-12 col-lg-4">
                  <div className="seckey1">
                    <span>Up to</span>
                    <span>
                      {" "}
                      <strong>$3</strong>
                    </span>
                    <span>Rebates</span>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="seckey1">
                    <span>Multi-lingual</span>
                    <span>
                      {" "}
                      <strong>Promotional</strong>
                    </span>
                    <span>Material</span>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="seckey1">
                    <span>Dedicated</span>
                    <span>
                      {" "}
                      <strong>Account</strong>
                    </span>
                    <span>Managers</span>
                  </div>
                </div>
              </div>
              <div className="marklis mt-5">
                <div className="row align-items-center">
                  <div className="col-lg-3"></div>
                  <div className="col-md-12 col-lg-6">
                    <div className="marklisitem1">
                      <p>
                    
                             <img
                          src={require("../../assets/images/introbroker/clientwithdraw.png")}
                          alt="logo"
                          className="img-fluid mx-2"
                        />
                        Client withdrawals and transfers are processed more
                        quickly.

                      </p>
                    </div>
                  </div>
                   <div className="col-lg-3"></div>
              
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ib mt-5">
          <div className="container">
            <div className="ibvintage">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3">Become a Milliva LTD IB Today</h2>
                  <p>
                    The IB program system is entirely scalable, allowing it to
                    accept all forms of IB accounts. Whether you are brand new
                    to the referral industry or a seasoned pro, our system is
                    built for you. Our trading-volume-based rebates are the most
                    competitive in the market. Milliva LTD provides solutions to
                    help you advance your business, and we want to develop solid
                    and lasting partnerships with you by supporting your growth.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/introbroker/introbroker2.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="becomeib mt-3">
          <div className="container">
            <div className="becomeibinsub">
              <h2 className="text-center">
                How to Become an Introducing Broker
              </h2>
              <div className="row align-items-center mt-5">
                <div className="col-md-12 col-lg-4">
                  <div className="sign1 d-flex align-items-center">
                    <div className="becom1 mx-3">
                      <img
                        src={require("../../assets/images/introbroker/ibsignup.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="becom2">
                      <h5 className="mb-2">Signup</h5>
                      <p>
                        Join our IB program today to take the first step in
                        growing your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="sign1 d-flex align-items-center">
                    <div className="becom1 mx-3">
                      <img
                        src={require("../../assets/images/introbroker/referib.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="becom2">
                      <h5 className="mb-2">Refer Clients</h5>
                      <p>
                        Use your personalized tracking links to send visitors to
                        our conversion-optimized landing pages.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="sign1 d-flex align-items-center">
                    <div className="becom1 mx-3">
                      <img
                        src={require("../../assets/images/introbroker/earnmoreib.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="becom2">
                      <h5 className="mb-2">Earn More</h5>
                      <p>
                        Earn substantial lifetime rebates on the trading
                        activities of clients you refer
                      </p>
                    </div>
                  </div>
                </div>
                <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                  <button type="button" className="btn btn-become mt-4 mb-5">
                    Become Ib
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Introbroker;
