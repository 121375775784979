import React,{useEffect} from "react";
import "../../assets/css/risk.css";
import { Link } from "react-router-dom";
const Risk = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
          top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <section className="Risk-trade">
        <section id="Risk">
          <section className="Risk-banner">
            <div className="Risk-trade-banner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h1 className=" mb-2">Risk Management</h1>
                    <p>
                      To ensure that the negative effect of the transaction is manageable. Milliva LTD helps you to ensure risk management practices
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/risk/riskbanner.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* ================================banner-next=================== */}
        <div className="risk-management mt-3">
          <div className="container">
            <div className="risk-text mt-5 mb-5">
              <h2 className="text-center">Risk Management</h2>
              <p className="text-center">
                For a successful Forex trader, risk management is a vital term
                for those who wish to make a long-term profit with minimalistic
                risk. Both beginners and experienced traders should employ this
                strategy. Even traders can trade without risking their profit
                margins. The trading platform, like Milliva LTD, provides a
                proper risk management practice that can be ensured using AI,
                most importantly Machine learning.{" "}
              </p>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h2>What Is The Way To Employ Risk Management?</h2>
                <p>
                  Nowadays, traders or any other type of trade or investor can
                  be flexible on rules, which is the most efficient way to
                  manage risk. The proper risk prevention method is to keep it
                  low and measure it from various perspectives. With the
                  contemporary practice of algorithmic trading, risk management
                  now implies ensuring no risks concerning other aspects like
                  data quality, technology and more.{" "}
                </p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Risk">
                  <img
                    src={require("../../assets/images/risk/risk-o-meter3.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>

              <h2>Risk Management Advantages</h2>
              <p>
                While in Forex trading, risk management plays a vital role in
                determining whether you lose or make money.
              </p>
              <p>
                {" "}
                Controlling your trade lock size hedging combines numerous
                technologies for limiting trading risk. Simply, risk management
                keeps you in control of your risk.
              </p>
              <p>
                Risk management takes place in trading to develop the trading
                portfolio with a strategy and around the trading activity.
              </p>
              <p>
                {" "}
                The online brokers for stock trading, like Milliva LTD, will do
                it efficiently for you.
              </p>
            </div>
          </div>
        </div>
        {/* =================================get started===================================== */}
        <section className="getmt5 mt-4 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center mb-3">Get Started</h2>
              <p className="getapara">
                Get start trade anywhere, anytime. We have everything you need:
                friendly customer support, the latest technology, and multiple
                account types to find the trades.
              </p>
              <div className="row mt-5 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/signupicon2.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Signup</h5>
                        <p className="mb-0 font-13">
                          Enter your details and setup an account
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/verifyicon2.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Verify</h5>
                        <p className="mb-0 font-13">
                          Upload your documents and get verified instantly
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/depositicon1.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Deposit</h5>
                        <p className="mb-0 font-13">Deposit Minimum as $100</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/tradeicon1.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Trade</h5>
                        <p className="mb-0 font-13">
                          Start trading any instrument of your choice and make
                          profits
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <Link to="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Live Account
                          </button>
                        </Link>
                      </div>
                      <div className="opendemobtn">
                        <Link to="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Demo Account
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                    <img
                      src={require("../../assets/images/getstartimg.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Risk;
