import React, { useState, useRef } from "react";
import "../../assets/css/liveacc.css";
import { FaUser } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { useForm, Controller } from "react-hook-form";
import { VALIDATION_PATTERN } from "../Signin/Store";
import BioCalendar from "./Calender";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
const Liveacc = () => {
  const ref = useRef(null);

  const [icon, setIcon] = useState(eyeOff);

  const [type, setType] = useState("password");

  const [type1, setType1] = useState("password");

  const [icon1, setIcon1] = useState(eyeOff);

  const {
    register: register1,
    handleSubmit: handleSubmitMaintenance,
    getValues,
    clearErrors,
    setValue: setProfileValue,
    clearErrors: clearprofileErrors,
    setError: setprofileError,
    control,
    watch: watch1,

    formState: { errors: errors1 },
    reset: profilereset,
  } = useForm();

  const newpassword = useRef({});

  newpassword.current = watch1("password", "");

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);

      setType("text");
    } else {
      setIcon(eyeOff);

      setType("password");
    }
  };

  const handleToggle1 = () => {
    if (type1 === "password") {
      setIcon1(eye);

      setType1("text");
    } else {
      setIcon1(eyeOff);

      setType1("password");
    }
  };

  const submit = () => {
    alert("hii");
    console.log("ee");
  };

  return (
    <div>
      <>
        <section className="open-live-page pb-5">
          <div className="container">
            <div className="row">
              <form onSubmit={handleSubmitMaintenance(submit)}>
                {" "}
                <div className="col-lg-12 col-md-12 col-12 mt-5 pt-4">
                  <div className="live-page1 mt-5 py-3">
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <div className="text-center">
                          <img
                            src={require("../../assets/images/home/logo.png")}
                            alt="logo"
                            className="img-fluid mt-3"
                            style={{ width: "50", height: "10" }}
                          />
                        </div>
                        <div className="text-center mt-3">
                          <h2 className="">Sign Up To Get Access</h2>
                        </div>
                      </div>
                      <div className="col-lg-12 col-12">
                        <div className="live-icon text-center mt-3">
                          <i className="menu">
                            <FaUser />
                          </i>
                        </div>
                      </div>

                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Your Name"
                            {...register1("registername", {
                              required: {
                                value: true,
                                message: "Select a name",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors1.registername &&
                              errors1.registername.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Gender
                        </label>

                        <select
                          className="form-select"
                          aria-label="Default select example"
                          {...register1("gender", {
                            required: {
                              value: true,
                              message: "Select a gender",
                            },
                          })}
                        >
                          <option value="">Select Gender</option>
                          <option value={1}>Male</option>
                          <option value={2}>Female</option>
                        </select>
                        <p className="text-danger">
                          {errors1?.gender && errors1.gender.message}
                        </p>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Your Mail"
                            {...register1("email", {
                              required: {
                                value: true,
                                message: "Email is required",
                              },
                              pattern: {
                                value: VALIDATION_PATTERN.EMAIL,
                                message: "Enter Valid Email",
                              },
                            })}
                          />
                          <div>
                            <p className="text-danger">
                              {errors1.email && (
                                <span role="alert">Email is required</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Your Address"
                            {...register1("address", {
                              required: {
                                value: true,
                                message: "Please enter your address",
                              },
                              pattern: {
                                value:
                                  /^()(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
                                message: "Please enter a valid address",
                              },
                            })}
                          />
                          <div>
                            <p className="text-danger">
                              {errors1.address && (
                                <span role="alert">
                                  Please enter your address
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Password
                          </label>
                          <input
                            type={type}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Your Password"
                            {...register1("password", {
                              required: {
                                value: true,
                                message: "Please enter a new password",
                              },
                              pattern: {
                                value:
                                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
                                message:
                                  "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                              },
                            })}
                          />
                          <div className="passIcon1" onClick={handleToggle}>
                            <Icon icon={icon} />
                          </div>
                          <p className="text-danger">
                            {errors1.password && errors1.password.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="City"
                            {...register1("city", {
                              required: {
                                value: true,
                                message: "Please enter a city",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors1.city && errors1.city.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type={type1}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Confirm Password"
                            {...register1("confirmPassword", {
                              required: {
                                value: true,
                                message: "Please enter a confirm password ",
                              },
                              validate: (value) =>
                                value === newpassword.current ||
                                "New password & confirm password  does not match",
                            })}
                          />
                          <div className="passIcon1" onClick={handleToggle1}>
                            <Icon icon={icon1} />
                          </div>
                          <p className="text-danger">
                            {errors1.confirmPassword?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="State"
                            {...register1("state", {
                              required: {
                                value: true,
                                message: "Please enter a state",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors1.state?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Country Code
                        </label>

                        <select
                          {...register1("country", {
                            required: {
                              value: true,
                              message: "Please enter a country",
                            },
                          })}
                          name="country"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="">Select Country</option>
                          <option value={1}>India</option>
                          <option value={2}>Australia</option>
                        </select>
                        <p className="text-danger">
                          {errors1.country?.message}
                        </p>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Zip Code
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Zipcode"
                            {...register1("zipcode", {
                              required: {
                                value: true,
                                message: "Please enter a zip code",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors1.zipcode?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Phone
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Phone"
                            {...register1("mobile_no", {
                              required: {
                                value: true,
                                message: "Please enter a phone number",
                              },
                            })}
                          />
                          <p className="text-danger">
                            {errors1.mobile_no?.message}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Birth Date
                          </label>

                          <div className="input-group date" id="datepicker">
                            <Controller
                              control={control}
                              name="dob"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Select your birth date",
                                },
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  className="form-control"
                                  // selected={dateofbirth}

                                  dateFormat="MM / DD / yyyy"
                                  showYearDropdown
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  adjustDateOnChange
                                  dropdownMode="select"
                                  onKeyDown={"return false"}
                                  isClearable
                                  customInput={<BioCalendar />}
                                />
                              )}
                            />
                            <p className="text-danger">
                              {errors1.dob && errors1.dob.message}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-3">
                        <div className="form-check">
                          <input
                            className={`form-check-input mx-2  ${
                              errors1.checkbox ? "border-danger" : ""
                            }`}
                            type="checkbox"
                            defaultValue=""
                            id="flexCheckDefault"
                            {...register1("checkbox", {
                              required: { value: true },
                            })}
                          />
                          <label
                            className={`form-check-label ${
                              errors1.checkbox ? "text-danger" : ""
                            }`}
                            htmlFor="flexCheckDefault"
                          >
                            I Accept{" "}
                            <a href="#">
                              Terms &amp; Conditions and Privacy-Policy
                            </a>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-3">
                        <div className="live-button1 text-center py-3">
                          <button
                            type="submit"
                            className="btn btn-primary "
                            value="submit"
                          >
                            Register
                          </button>
                          <Link to="/signin">
                            <button
                              type="button"
                              className="btn btn-primary live2"
                            >
                              Sign In
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Liveacc;
